<template lang="pug">
.patient-exams-card
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showExams"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Последние осмотры пользователя
  // patient exams
  .position-relative
    transition(name="fade" mode="out-in")
    .patient-exams-card__wrapper
    .mt-15(v-if="showInfo && exams.length")
      section(class=['patient-exams', 'profile-card'])(v-if="showInfo && exams.length")

        ul.patient-exams__list(id="patient-exams")
          li.patient-exams__list-el(v-for="exam in exams" :key="exam.exam_id")
            app-patient-exam-card(
              :parent-id="'patient-exams'"
              :classes="'d-none d-xl-block'"
              :data="exam"
              @openModal="openExamDetailModal"
              @openGallery="openGallery"
              @openCertificate="openCertificate"
            )

      .mt-5.text-color--blue.text-size--small(v-else) Осмотров не найдено

  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )
</template>

<script>
import patients from '@/api/patients';

import AppPatientExamCard from '@/components/profile/common/patients/PatientExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppGallery from '@/components/common/Gallery';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'AppPatientExams',
  components: {
    AppPatientExamCard,
    AppExamCardMobile,
    AppCertificateModal,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppGallery,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, certificateModalMixin, galleryMixin],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInfo: false,
      exams: {},
      pagination: {
        limit: 3,
        offset: 3,
      },
      offset: 3,
    };
  },
  created() {
    this.getExams();
  },
  methods: {
    async showExams() {
      this.showInfo = !this.showInfo;
    },
    async getExams() {
      let string = `${this.userId}?page=0&per_page=10&exams_order_by=-exam_datetime`;
      let data = await patients.getPatientExams(string);
      this.exams = data.data.items;
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },

    openModal(slug, data) {
      this.$emit('examDetail', slug, data);
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
  },
};
</script>

<style lang="scss">
.block-history-title:hover {
  cursor: pointer;
}

.user-card-history {
  padding: 10px 0;
  color: #505e8e;
}
</style>
