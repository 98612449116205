<template lang="pug">
div
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showBlockHistory"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Индивидуальные границы выпуска

  .profile-table(v-if='showInfo')
    .profile-table__header.d-none.d-xl-block
      .row.align-items-center.g-4
        div(v-for="column in columns" :key="column.key" :class="column.class")
          button(
            type="button"
            @click="alert(123)"
            class=['profile-table__sort-button']
          )
          div.d-flex.align-items-center
            span.text-overflow(:title="column.caption") {{ column.caption }}

        ul.profile-table__body
          li.profile-table__row(v-for="border in bordersIds" :key="border.num")
              section(class=['user-card', 'user-card-history'])
                .row.align-items-center.g-4.gy-xl-0
                  .col-3
                      div {{ border.name }}
                  .col-4.d-flex
                    span.presonal-border-text от
                    input(
                      :disabled="currentUser.role === 'CLIENT'"
                      :class="['personal-border', 'form__field']"
                      type="email"
                      placeholder="от"
                      v-model="data[border.id].min_value"
                    )
                    span.presonal-border-text до
                    input(
                      :disabled="currentUser.role === 'CLIENT'"
                      :class="['personal-border', 'form__field']"
                      type="email"
                      placeholder="до"
                      v-model="data[border.id].max_value"
                    )
                  .col-2
                    date-picker(
                      :disabled="currentUser.role === 'CLIENT'"
                      prefix-class="app"
                      placeholder="от"
                      format="DD.MM.YYYY"
                      :editable="true"
                      v-model="data[border.id].end_date"
                      value-type="YYYY-MM-DD"
                    )
                  .col-1(v-if="currentUser.role !== 'CLIENT'")
                    button(
                      type="button"
                      @click="sendBorder(data[border.id])"
                      class=['text-decoration--underline text-size--small link--accent']
                    ) Сохранить

</template>

<script>
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import DatePicker from 'vue2-datepicker';

import AppPatientPersonalBorder from './AdminPatientPersonalBorder.vue';

import patientApi from '@/api/patients';
import AppSvgIcon from '@/components/common/SvgIcon';
import moment from 'moment';

export default {
  name: 'AppPatientPersonalBorders',
  components: {
    DatePicker,
    AppPatientPersonalBorder,
    AppSvgIcon,
    moment,
  },
  props: {
    patientCode: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showInfo: false,
      data: {},
      bordersIds: [
        {
          id: 'bp_high',
          num: 1,
          name: 'Давление систолическое',
        },
        {
          id: 'bp_low',
          num: 2,
          name: 'Давление диастолическое',
        },
        {
          id: 'heart_rate',
          num: 3,
          name: 'ЧСС',
        },
        {
          id: 'body_temp',
          num: 4,
          name: 'Температура',
        },
        {
          id: 'alcohol',
          num: 5,
          name: 'Алкоголь',
        },
      ],
      columns: [
        {
          num: 1,
          class: 'col-3',
          caption: 'Параметр',
          key: 'parameter',
          inverse: false,
        },
        {
          num: 2,
          class: 'col-4',
          caption: 'Значение',
          key: 'meaning',
          inverse: false,
        },
        {
          num: 2,
          class: 'col-3',
          caption: 'Срок действия',
          key: 'end_date',
          inverse: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  methods: {
    async showBlockHistory() {
      this.showInfo = !this.showInfo;
      let result = await patientApi.getPatientBorders(this.patientCode);
      this.data = result.data;
    },
    async sendBorder(border) {
      let endDate = null;
      if (border.end_date !== null) {
        endDate = this.$moment(border.end_date).format('YYYY-MM-DD');
      }
      this.data[border.border_type].border_type = border.border_type;
      this.data[border.border_type].end_date = endDate;
      this.data[border.border_type].max_value = border.max_value;
      this.data[border.border_type].min_value = border.min_value;

      await patientApi.updatePersonalBorders(this.patientCode, this.data);
      this.$toast.success('Персональные границы изменены');
    },
  },
};
</script>

<style lang="scss">
.block-history-title:hover {
  cursor: pointer;
}

.user-card-history {
  padding: 10px 0;
  color: #505e8e;
}

.personal-border {
  width: 60px !important;
}

.presonal-border-text {
  padding: 10px;
}
</style>
