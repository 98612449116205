<template lang="pug">
.js-status.profile-status.flex-shrink-0(:class="[classes, `${theme}-theme`]" :data-status="status.className")
  .d-md-none(v-if="line")
    span.profile-status__top-line(:class="status.className")

  div(:class="{'d-none d-md-block': line}")
    template(v-if="type === 'device'")
      span(:class="`text-color--${status.color}`")
        app-svg-icon(:name="status.icon")

    template(v-else-if="type === 'exam'")
      .d-flex.align-items-center(:class="`text-color--${status.color}`")
        span.d-flex.me-3
          app-svg-icon(name="circle")
        span.profile-status__caption(v-if="caption") {{ status.caption }}

    template(v-else-if="type === 'doctor'")
      span.profile-status__bottom-line(v-if="theme === 'bottom-line'" :class="status.className")
      .d-flex.align-items-center(:class="[theme === 'bottom-line' ? 'text-color--white' : `text-color--${status.color}`]")
        span.d-flex.me-3
          app-svg-icon(:name="status.icon")
        span.profile-status__caption(v-if="caption") {{ status.caption }}

    template(v-else)
      span(:class="`text-color--${status.color}`") {{ status.caption }}

</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppStatus',
  components: {
    AppSvgIcon,
    AppTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: true,
      default: 'device',
    },
    line: {
      type: Boolean,
      required: false,
      default: true,
    },
    caption: {
      type: Boolean,
      required: false,
      default: true,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
    theme: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      statuses: {
        success: {
          className: 'is-success',
          caption: 'Допущен',
          icon: 'allright',
          color: 'success',
          hint: 'Пользователь допущен к&nbsp;выполнению трудовых обязанностей',
        },
        passed: {
          className: 'is-success',
          caption: 'Прошел',
          icon: 'allright',
          color: 'success',
          hint: 'Пользователь допущен к&nbsp;выполнению трудовых обязанностей',
        },
        viewing: {
          className: 'is-warning',
          caption: 'Не доставлен',
          icon: 'wrong',
          color: 'blue',
        },
        warning: {
          className: 'is-warning',
          caption: 'Нет заключения',
          icon: 'process',
          color: 'blue',
          hint: 'Осмотр не проверен медработником',
        },
        error: {
          className: 'is-error',
          caption: 'Не допущен',
          icon: 'wrong',
          color: 'danger',
          hint: 'Пользователь не допущен к&nbsp;выполнению трудовых обязанностей',
        },
        failed: {
          className: 'is-error',
          caption: 'Не прошел',
          icon: 'wrong',
          color: 'danger',
          hint: 'Пользователь не допущен к&nbsp;выполнению трудовых обязанностей',
        },
        notCompleted: {
          className: 'is-not-completed',
          caption: 'Не завершён',
          icon: '',
          color: 'blue',
          hint: 'Осмотр не завершён',
        },
      },
    };
  },
  computed: {
    status() {
      if (this.type === 'device') {
        return this.data.approved === false
          ? this.statuses.error
          : this.data.approved === true
          ? this.statuses.success
          : this.data.approved === null
          ? this.statuses.warning
          : this.statuses.warning;
      }

      if (!this.data.exams_data_id) {
        return this.statuses.viewing;
      } else if (
        this.data.type_id === 2 ||
        this.data.type_id === 4 ||
        this.data.type_id === 5 ||
        this.data.type_id === 6
      ) {
        return this.data.exam_completed === false
          ? this.statuses.notCompleted
          : this.data.exam_completed === true && this.data.admission_to_work === false
          ? this.statuses.failed
          : this.data.exam_completed === true && this.data.admission_to_work === true
          ? this.statuses.passed
          : this.data.exam_completed === true && this.data.admission_to_work === null
          ? this.statuses.warning
          : this.statuses.viewing;
      } else if (this.data.type_id === 1 || this.data.type_id === 3) {
        return this.data.exam_completed === false
          ? this.statuses.notCompleted
          : this.data.exam_completed === true && this.data.admission_to_work === false
          ? this.statuses.error
          : this.data.exam_completed === true && this.data.admission_to_work === true
          ? this.statuses.success
          : this.data.exam_completed === true && this.data.admission_to_work === null
          ? this.statuses.warning
          : this.statuses.viewing;
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss">
.profile-status {
  &.bottom-line-theme {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $spacer * 3.6;
    left: 0;
    bottom: 0;
  }
  &__top-line {
    position: absolute;
    width: 100%;
    height: $spacer * 0.8;
    left: 0;
    top: 0;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
  }
  &__bottom-line {
    position: absolute;
    z-index: get($index, negative);
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
  }
  &__top-line,
  &__bottom-line {
    &.is-success {
      background: get($gradients, green);
    }
    &.is-warning {
      background: get($gradients, orange);
    }
    &.is-error {
      background: get($gradients, red);
    }
    &.is-not-completed {
      background-color: get($colors, gray-hover);
    }
  }
  .icon {
    &__circle {
      width: $spacer;
      height: $spacer;
    }
    &__eye-open {
      width: $spacer * 2;
      height: $spacer * 1.2;
    }
  }
}
</style>
