<template lang="pug">
.profile-users
  .profile-exams__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-users__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(
              :type="'large'"
              :classes="'text-center mb-3 mb-md-0'"
            ) {{ isTouchDevice ? 'Клиенты' : 'Просмотр клиентов' }}

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              @click="openCreateClientModal"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить клиента

    .profile-users__content.mt-20.position-relative
      app-error-message(v-if="error" :message="error")
      transition(name="translucent")
        div(v-if="users")
          template(v-if="users && users.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingUsers(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key && column.key !== 'exam_count'}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(v-if="column.caption") {{ column.caption }}
                        span(v-else-if="column.icon")
                          app-svg-icon(:name="column.icon")
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="user in users")
                  router-link(:to="{name: 'user-detail', params: {id: user.id}}" class=['d-block'])
                    app-admin-user-card(:data="user")

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Клиенты не найдены


    // filter
    app-admin-user-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      @open="openFilter"
      @close="close"
      @clear="close"
      @submit="submitFilter"
    )

    // create doctor modal
    app-create-client-modal(
      :close="closeClientCreateModal"
      :isVisible="isVisibleForm"
      @success="getUsers(pagination.limit, 0)"
    )

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/users';

import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';

import AppAdminUserCard from '@/components/profile/admin/users/AdminUserCard';
import AppAdminUserFilter from '@/components/profile/admin/users/AdminUserFilter';

import {filterMixin} from '@/mixins/filter';

import AppCreateClientModal from '@/components/profile/admin/users/CreateClientModal';


import UsersApi from '@/api/users';

export default {
  name: 'AppAdminUsers',
  components: {
    AppAdminUserCard,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppSelect,
    AppAdminUserFilter,
    AppCreateClientModal
  },
  mixins: [filterMixin],
  data() {
    return {
      pagination: {
        limit: 10,
        total: 0,
      },
      isVisibleForm: false,
      sortKey: 'creation_date',
      filterString: '',
      isFilterOpen: false,
      v: variables,
      users: {},
      filterOptions: {
        users: true,
        userTypes: true,
        date: true,
        devices: true,
        methods: {
          getData: ['getUsers'],
          getCount: 'getCountUsers',
        },
      },

      sortInverse: false,
      columns: [
        {
          class: 'col-2',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Тип клиента',
          key: 'user_type',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Клиент',
          key: 'company_name',
          inverse: true,
        },
        {
          class: 'col-md-4 col-xxl-4',
          caption: 'E-mail',
          key: 'email',
          inverse: true,
        },
        {
          class: 'col-md-3 col-xxl-3',
          caption: 'Осмотры',
          key: 'exams_count',
          inverse: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.users.error,
    }),
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getUsers();
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    openCreateClientModal() {
      this.isVisibleForm = true
    },
    async closeClientCreateModal() {
      this.isVisibleForm = false
      await this.getUsers();
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    async getUsers() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }

      let order_by = `${sortInverse}${this.sortKey}`;
      let resultFilter =
        `?page=${this.currentPage - 1}&per_page=10${this.filterString}` + `&order_by=${order_by}`;

      let res = await UsersApi.getUsersNew(resultFilter);
      this.users = res.data.items;

      console.log(res);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;

      console.log(this.pagination.total);
    },
    getCountUsers(filters) {
      let data = {
        sorting_params: this.settings.sorting_params,
      };
      data.filters = Object.assign({}, this.settings.filters, filters);

      this.$store.dispatch(actionTypes.getCountUsers, data);
    },
    sortingUsers(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getUsers();
      }
    },
    async submitFilter(data) {
      this.filterString = data;
      this.getUsers();
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
  },
};
</script>

<style lang="scss">
.profile-users {
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
