<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" :classes="`doctor-exam-detail-modal modal-reasons`")
    template(v-slot:body)
      .text-center.mb-2
        app-title(:type="'large'" v-if="!type") Выберите причину отстранения
        app-title(:type="'large'" v-else) Укажите причину
      p.text-size--small.text-color--blue.mt-0.pt-0.text-uppercase.text-center {{ data.patient_full_name }}
      .row.justify-content-center.mt-5
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="pulse")
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_high_alarm}') {{ data.bp_high }}/
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="cardio")
            span(v-if="data.heart_rate" :class='{yellowText: data.heart_rate_alarm}') {{ data.heart_rate }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="temp")
            span(v-if="data.body_temp" :class='{yellowText: data.body_temp_alarm}') {{ data.body_temp + "\u2103" }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="breathalyzer")
            span(v-if="data.alcohol >= 0  && data.alcohol !== null" :class='{yellowText: data.alcohol_alarm}') {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
            span(v-else) &#8212;

      .row
        .col-14
          .reasonsHeader Медицинские
          .reasons
            template(v-for='reason in reasonsByType.medical')
              .reason.reason-input
                input(v-if="reason.title !== 'Травма'" type='checkbox' :id="'med'+reason.id" v-model='reason.checked' :disabled='reason.checked && !protector && !reason.manuallyAdded' @change='addReason(reason)')
                label(v-if="reason.title !== 'Травма'" :for="'med'+reason.id") {{reason.title}}
                input(v-if="reason.title == 'Травма'" type='checkbox' :checked='isAnyInjurySelected' @click='showinjuries(reason.title)')
                label(v-if="reason.title == 'Травма'" :for="'med'+reason.id" @click='showinjuries(reason.title)') {{reason.title}}

        .col-14.mt-5(v-if="injury")
          .row
            .col-7
              .row.mb-4
                .col-8.black.text-bold Локализация травмы
                .col-6
                  .row
                    .col-auto.black.text-bold Слева
                    .col-auto.black.text-bold Справа
                    .col-auto.black.text-bold Общая
            .col-7
              .row.mb-4
                .col-8.black.text-bold Локализация травмы
                .col-6
                  .row
                    .col-auto.black.text-bold Слева
                    .col-auto.black.text-bold Справа
                    .col-auto.black.text-bold Общая
            .col-7(v-for='injurie in reasonsByType.injuries' :key='injurie.id')
              .row.align-items-center.mb-3
                .col-8 {{injurie.title}}
                .col-6
                  .row
                    .col-auto.reason-short(v-if='injurie.left !== null')
                      .reason
                        input(:id="'left'+injurie.id" v-model='injurie.left' type='checkbox' @change="checkInjuriePart(injurie)")
                        label(:for="'left'+injurie.id")
                    .col-auto.reason-short(v-if='injurie.right !== null')
                      .reason
                        input(:id="'right'+injurie.id" v-model='injurie.right' type='checkbox' @change="checkInjuriePart(injurie)")
                        label(:for="'right'+injurie.id")
                    .col-auto.reason-short(v-if='injurie.both !== null')
                      .reason
                        input(:id="'both'+injurie.id" type='checkbox' v-model='injurie.both' @change="checkInjurieBoth(injurie)")
                        label(:for="'both'+injurie.id")

        .col-14(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no' || type == '5no'" )
          .reasonsHeader Административные
          .reasons
            .reason.reason-input(v-for='reason in reasonsByType.administrative' :key='reason.id')
              input(type='checkbox' :id="'adm'+reason.id" v-model='reason.checked' @change="addReason(reason)")
              label(:for="'adm'+reason.id") {{reason.title}}

        .col-14(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no'  || type == '5no'" )
          .reasonsHeader Технические
          .reasons
            .reason.reason-input(v-for='reason in reasonsByType.technical' :key='reason.id')
              input(type='checkbox' :id="'tecn'+reason.id" v-model='reason.checked' @change="addReason(reason)")
              label(:for="'tecn'+reason.id") {{reason.title}}

        .col-14
          .reason.d-flex.justify-content-end(:class="classObject")
            button(
              type="button"
              @click="refuseOpen()"
              class=['button', 'button--accent','button--height', 'mt-3'])
                span.button__content.d-flex.align-items-center {{ btnContent }}

</template>

<script>
import {mapState} from 'vuex';

import reasonsIds from '@/components/profile/doctor/exams/reasons_ids.json';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required, minLength} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AddDoctorReasons',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object,
    },
    reject_reasons_ids: {
      required: true,
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      required: true,
      type: String,
      default() {
        return '';
      },
    },
    time: {
      required: true,
      type: Number,
      default: 0,
    },
    clearForm: {
      required: true,
      type: Boolean,
      default: false,
    },
    protector: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      injurySelected: false,
      v: variables,
      form: {
        version: '',
        controlSum: '',
      },
      result: [],
      injury: false,
      reasons: reasonsIds,
      reasonsByType: {
        medical: [],
        administrative: [],
        technical: [],
        injuries: [
          {
            id: 32,
            title: 'Волосистой области',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 37,
            title: 'Мягких тканей лица',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 34,
            title: 'Лобной области',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 39,
            title: 'Верхней губы',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 36,
            title: 'Височной области',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 41,
            title: 'Нижней губы',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 38,
            title: 'Надбровной области',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 43,
            title: 'Подбородочной области',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 40,
            title: 'Параорбитальная гематома',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 45,
            title: 'Множественные травмы головы',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 42,
            title: 'Века',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 47,
            title: 'Шеи неуточненная',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 44,
            title: 'Воспаление века неуточненное',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 49,
            title: 'Грудной клетки неуточненная',
            left: null,
            right: null,
            both: false,
          },
          {
            id: 46,
            title: 'Подглазничной области',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 51,
            title: 'Верхней конечности',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 48,
            title: 'Скуловой области',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 53,
            title: 'Нижней конечности',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 50,
            title: 'Спинки носа',
            left: null,
            right: null,
            both: false,
          },
          {
            id: 52,
            title: 'Носа',
            left: false,
            right: false,
            both: false,
          },
          {
            id: 33,
            title: 'Околоушно-жевательной области',
            left: false,
            right: false,
            both: null,
          },
          {
            id: 35,
            title: 'Уха',
            left: false,
            right: false,
            both: null,
          },
        ],
      },
    };
  },
  validations() {
    return {
      form: {
        version: {
          required,
          minLength: minLength(1),
        },
        controlSum: {
          required,
          minLength: minLength(1),
        },
      },
    };
  },
  mounted() {
    this.clearList();
  },
  watch: {
    isVisible(newValue) {
      if (newValue === true) {
        this.reasonsByType.medical = this.reasons.filter(function (el) {
          return el.type === 'medical';
        });
        this.reasonsByType.administrative = this.reasons.filter(function (el) {
          return el.type === 'administrative';
        });
        this.reasonsByType.technical = this.reasons.filter(function (el) {
          return el.type === 'technical';
        });

        for (let obj in this.reasonsByType) {
          this.reasonsByType[obj].forEach((element) => {
            element.id = Number(element.id);
            element.checked = this.reject_reasons_ids.includes(element.id);
            if (
              this.reject_reasons_ids.includes(element.id) &&
              !this.result.some((p) => p.id === element.id)
            ) {
              this.result.push(element);
            }
          });
        }
      }
    },
    clearForm(newValue) {
      if (newValue === true) {
        this.result = [];
        this.clearList();
      }
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
    classObject() {
      if (this.type == '2yes' || this.type == '4yes' || this.type == '5yes') {
        return 'w-100';
      } else {
        return '';
      }
    },
    btnContent() {
      if (this.type == '2yes' || this.type == '4yes' || this.type == '5yes') {
        return 'Прошел';
      } else if (this.type == '2no' || this.type == '4no' || this.type == '5no') {
        return 'Не прошел';
      } else {
        return 'Отстранить';
      }
    },
    isAnyInjurySelected() {
      return this.reasonsByType.injuries.some(
        (injury) => injury.left || injury.right || injury.both
      );
    },
  },
  methods: {
    showinjuries(text) {
      if (text === 'Травма') {
        this.injury = !this.injury;
      }
    },
    addReason(item) {
      let index = this.result.findIndex((el) => el.id === item.id);
      if (index === -1) {
        this.result.push(item);
        item.manuallyAdded = true;
      } else {
        this.result.splice(index, 1);
        item.manuallyAdded = false;
        if (this.protector) {
          item.checked = false;
        }
      }
    },

    checkInjurieBoth(item) {
      const id = item.id;
      let index = this.reasonsByType.injuries.findIndex((el) => el.id === id);
      if (this.reasonsByType.injuries[index]['both']) {
        if (this.reasonsByType.injuries[index]['left'] !== null) {
          this.reasonsByType.injuries[index]['left'] = true;
        }
        if (this.reasonsByType.injuries[index]['right'] !== null) {
          this.reasonsByType.injuries[index]['right'] = true;
        }
      } else {
        if (this.reasonsByType.injuries[index]['left'] !== null) {
          this.reasonsByType.injuries[index]['left'] = false;
        }
        if (this.reasonsByType.injuries[index]['right'] !== null) {
          this.reasonsByType.injuries[index]['right'] = false;
        }
      }
      this.addInjurie(item);
    },
    checkInjuriePart(item) {
      const id = item.id;
      let index = this.reasonsByType.injuries.findIndex((el) => el.id === id);
      if (this.reasonsByType.injuries[index]['both'] !== null) {
        if (
          this.reasonsByType.injuries[index]['right'] &&
          this.reasonsByType.injuries[index]['left']
        ) {
          this.reasonsByType.injuries[index]['both'] = true;
        } else {
          this.reasonsByType.injuries[index]['both'] = false;
        }
      }
      this.addInjurie(item);
    },
    addInjurie(item) {
      let index = this.result.findIndex((el) => el.id === item.id);
      if (index == -1) {
        this.result.push(item);
      } else {
        if (!this.result[index]['left'] && !this.result[index]['right']) {
          this.result.splice(index, 1);
        } else {
          this.result[index] = item;
        }
      }
    },
    clearList() {
      this.result.length = 0;
      this.injury = false;
      this.reasonsByType.medical.forEach((element) => {
        element.checked = false;
      });
      this.reasonsByType.administrative.forEach((element) => {
        element.checked = false;
      });
      this.reasonsByType.technical.forEach((element) => {
        element.checked = false;
      });
      this.reasonsByType.injuries.forEach((element) => {
        element.right = false;
        element.left = false;
        if (element.both !== null) {
          element.both = false;
        }
      });
    },
    refuseOpen() {
      this.$emit('refuseOpen', this.result);
    },
    closeModal() {
      this.clearList();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },
  },
};
</script>

<style lang="scss">
.yellowText {
  color: #ec9125;
}
.reasonsHeader {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 17px;
  margin-top: 35px;
}

.reasons {
  display: flex;
  flex-wrap: wrap;
}

.reason-input {
  width: calc(100% / 3);
  margin-bottom: 15px;
}

.reason {
  position: relative;
  display: block;
}

.reason input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.reason label {
  color: #59658d;
  position: relative;
  cursor: pointer;
}

.reason label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #59658d;
  border-radius: 4px;
  width: 27px;
  margin-right: 10px;
  height: 27px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.reason input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  height: 27px;
  border-radius: 4px;
  background: #59658d;
  border: 2px solid #59658d;
}

.reason input:disabled + label:before {
  content: '';
  background-color: #d1d4d8;
  border: 2px solid rgba(89, 101, 141, 0.5);
  border-radius: 4px;
  width: 27px;
  margin-right: 10px;
  height: 27px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: not-allowed;
}

.injuryTrigger {
  position: absolute;
  top: 3px;
  cursor: pointer;
  right: 0;
}

.mte-50 {
  margin-top: -33px;
}

.black {
  color: #000;
}

.text-bold {
  font-weight: 500;
}

.reasons--w66 {
  width: calc(100% - 33%);
}

.reason-short {
  width: 76px !important;
}

.button--height {
  height: 64px;
}
</style>
