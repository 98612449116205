<template lang="pug">
.inspection-card__signatures
  .signature.d-flex.flex-column.justify-content-center.align-items-center.text-align-center.position-relative
    p.signature__caption Подпись осмотра
    app-signature.align-self-stretch(
    :localUrl="exam.signature"
    :theme="'square'"
    :classes="'large d-flex justify-content-center'"
    @openGallery="openGallery"
    )
  .signature.d-flex.flex-column.justify-content-center.align-items-center.text-align-center
    p.signature__caption Подпись оригинал
    app-signature(
    :localUrl="patientSign"
    :theme="'square'"
    :classes="'large d-flex justify-content-center'"
    @openGallery="openGallery"
    )

</template>

<script>
import AppSignature from '@/components/profile/common/Signature';
import AppLoading from '@/components/common/Loading';
import patientApi from '@/api/patients';

import AppGallery from '@/components/common/Gallery';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'DoctorExamCardSignatures',
  components: {
    AppSignature,
    AppLoading,
    AppGallery,
  },
  mixins: [galleryMixin],
  props: {
    exam: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      patientSign: '',
      isLoading: false,
    };
  },
  watch: {
    async exam(newVal) {
      if (newVal) {
        await this.getPatientSignature();
      }
    },
  },
  methods: {
    async getPatientSignature() {
      this.isLoading = true;
      try {
        let res = await patientApi.getPatientDetail(this.exam.patient_code);
        this.patientSign = res.data.signature;
      } catch (error) {
        // Обработка ошибки, если нужно
        console.error('Ошибка при получении подписи пациента', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.inspection-card__signatures {
  .signature {
    height: 125px;
    margin-bottom: 7px;
    border-radius: 2px;
    border: 1px solid #dfe4f8;
    .profile-signature__button {
      width: 100%;
    }
    .signature__caption {
      margin-bottom: 5px;
      text-align: center;
      @include font-size(get($text-sizes, normal));
    }
  }
}
</style>
