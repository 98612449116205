<template lang="pug">
div
    h1 Документы
    .card(v-for="(value, name) in docs")
        a(v-bind:href="value") {{ name }}
</template>

<script>
import docs from '@/api/docs';

export default {
  name: 'Docs',
  data() {
    return {
      docs: [],
    };
  },
  async created() {
    const result = await docs.getSettings();
    this.docs = result.data;
  },
};
</script>
