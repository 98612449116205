<template lang="pug">
  section(
    class=['exam-card']
    :class="classes"
    @click="onOpen"
  )
    app-accordion(
      :id="data.exams_id"
      :parent-id="parentId"
      :classes="'profile-card'"
    )
      template(v-slot:header)
        .exam-card__header
          .row.align-items-center.g-4
            div(v-for="column in columns" :key="column.key" :class="column.class")
              template(v-if="column.key === 'exam_datetime' || column.key === 'exam_date_time'")
                span {{ toLocalDateExam(data.exam_date_time) || toLocalDateExam(data.exam_datetime)}}
                span.ms-2 {{ toLocalTimeExam(data.exam_date_time) || toLocalTimeExam(data.exam_datetime)}}

              template(v-else-if="column.key === 'patient_full_name' || column.key === 'patient_code'")
                span(v-if="data.patient_full_name") {{ data.patient_full_name }}
                span(v-else) &#8212;

              template(v-else-if="column.key === 'lat'")
                span(v-if="data[column.key] && data.long") {{ data[column.key] }}, {{ data.long }}
                span(v-else) &#8212;

              template(v-else-if="column.key === 'bp_high'")
                span(v-if="data[column.key] && data.bp_low")
                  span(:class='{yellowText: data.bp_high_alarm}') {{ data[column.key] }}/
                  span(:class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
                span(v-else) &#8212;

              template(v-else-if="column.key === 'heart_rate'")
                span(v-if="data[column.key]" :class='{yellowText: data.heart_rate_alarm}') {{ data[column.key] }}&nbsp;уд&#65279;/&#65279;мин
                span(v-else) &#8212;

              template(v-else-if="column.key === 'body_temp'")
                span(:class='{yellowText: data.body_temp_alarm}' v-if="data[column.key]") {{ data[column.key] + "\u2103"}};
                span(v-else) &#8212;

              template(v-else-if="column.key === 'alcohol'")
                span(v-if="data[column.key] || data[column.key] === 0")
                  span(:class='{yellowText: data.alcohol_alarm}') {{ data[column.key] }}&nbsp;мг&#65279;/&#65279;л
                span(v-else) &#8212;

              template(v-else-if="column.key === 'health_complaint'")
                app-tooltip(
                  v-if="data[column.key]"
                  :content="`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--black\">${data[column.key]}<p>`"
                  :icon="'comment'"
                )

              template(v-else-if="column.key === 'admission_to_work'")
                .exam-card__status.d-flex
                  app-status(:type="'exam'" :data="data" :id="data.exams_id")

              template(v-else)
                span(v-if="data[column.key]") {{ data[column.key] }}
                span(v-else) &#8212;

      template(v-slot:body)
        .exam-card__content
          .exam-card__content-info
            .exam-card__content-info-item.pt-20.pb-4(v-if="userRole === 'admin' && type !== 'device-detail'")
              .row
                .col(v-if="type !== 'user-detail'")
                  span.mb-3 Владелец устройства:&ensp;
                  span(v-if="data_detail.device_owner_full_name") {{ data_detail.device_owner_full_name }}

            .exam-card__content-info-item.py-20
              .row.g-4
                .col-2
                  .text-color--blue.mb-2 Подпись и e-mail
                  .d-flex.align-items-center.text-wrap
                    app-signature(
                      v-if="data_detail.signature"
                      :instance='data_detail.signature'
                      :theme="'shadow'"
                      :classes="'small'"
                      @openGallery="openGallery"
                    )
                    ul.ms-20
                      li(v-if="data_detail.email || data_detail.patient_login") {{ data_detail.email ? data_detail.email : data_detail.patient_login }}
                      li(v-else) &#8212;

                .col-2
                  .text-color--blue.mb-2 Координаты
                  span(v-if="data_detail.geo_location_lat >= 0 && data_detail.geo_location_long >= 0") {{ trimNum(data_detail.geo_location_lat) }}, {{ trimNum(data_detail.geo_location_long) }}
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Тип осмотра
                  span(v-if="data_detail.exam_type_name") {{ data_detail.exam_type_name }}
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Жалобы
                  ul(v-if='data_detail.health_complaint')
                    li {{ data_detail.health_complaint }}
                  span(v-else) &#8212;
                .col-1
                  .text-color--blue.mb-2 Статус
                  span(v-if="data_detail.exam_completed === true") Завершен
                  span(v-else-if="data_detail.exam_completed === false").text-color--orange Не завершен
                  span(v-else) &#8212;
                .col-2
                  template
                    .text-color--blue.mb-2 Медработник
                    .d-flex(v-if="userRole === 'admin' && data_detail.doctor_full_name")
                      router-link(
                        :to="{name: 'doctor-detail', params: {id: data_detail.doctor_id}}"
                        class="text-decoration--underline link--accent"
                      ) {{ data_detail.doctor_full_name }}
                    span(v-else-if="data_detail.doctor_full_name") {{ data_detail.doctor_full_name }}
                    span(v-else) &#8212;
                .col-1
                  template(v-if="data_detail.doctor_id")
                    .text-color--blue.mb-2.d-flex.flex-column &nbsp;
                      span.doctorSign(type="button" @click.prevent="openCertificate(data_detail)")
                .col-2
                  .text-color--blue.mb-2 Причины отказа
                  span(v-if="data_detail.exam_comment && data_detail.exam_comment !== 'undefined' || data_detail.reasons_array && data_detail.exam_comment !== 'undefined'  ") {{data_detail.exam_comment}}
                    span.text-wrap(v-if="data_detail.reasons_array")
                        ul(v-for="reason in data_detail.reasons_array")
                          li {{reason}}
                  span(v-else) &#8212;

                .col-14
                  hr.mt-0.mb-0
                .col-2
                  .text-color--blue.mb-2 Серийный номер устройства
                  span(v-if="data_detail.device_serial") {{ data_detail.device_serial }}
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Состояние корпуса
                  template(v-if="data_detail.case_opened")
                    .d-flex.align-items-center.text-color--danger
                      span.text-color--orange.me-2 Вскрыт
                      span.d-flex
                        app-svg-icon(name="attention")
                  template(v-else)
                    span Не вскрыт
                .col-2
                  .text-color--blue.mb-2 Контроль ПО
                  span(v-if="data_detail.device_integrity !== null")
                    span(v-if='data_detail.device_integrity') Пройден
                    span.text-color--orange(v-else) Не пройден
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Температура
                  span(v-if='data_detail.area_temp >= 0') {{data_detail.area_temp + "\u2103" }}
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Влажность
                  span(v-if='data_detail.area_humidity') {{data_detail.area_humidity + `%` }}
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Освещенность
                  span(v-if='data_detail.lux') {{data_detail.lux + `Лк` }}
                  span(v-else) &#8212;
                .col-2
                  .text-color--blue.mb-2 Поверка устройства
                  span(v-if='data_detail.device_verified') Поверено
                  span(v-else).text-color--orange Не поверено
          .exam-card__content-footer.py-4
            .row.align-items-center.g-4.justify-content-between
              .col-auto
                .row.align-items-center
                  .col-auto(v-if="data_detail.patient_code && $route.name !== 'doctor-card-revieved' && $route.name !== 'exam_queue'")
                    router-link(
                      :to="{name: 'patient-detail', params: {id: data_detail.patient_code}}"
                      class=['d-flex align-items-center', 'link--accent']
                    )
                      span.me-2
                        app-svg-icon(name="search")
                      span Поиск по пользователю

                  .col-auto(v-if="userRole === 'admin' && type !== 'user-detail' && data_detail.device_owner_id && $route.name !== 'user-detail'")
                    router-link(
                      :to="{name: 'user-detail', params: {id: data_detail.device_owner_id}}"
                      class=['d-flex align-items-center', 'link--accent']
                    )
                      span.me-2
                        app-svg-icon(name="search")
                      span Поиск по владельцу

                  .col-auto(v-if="data_detail && data_detail.exam_video")
                    button(
                      type="button"
                      @click="openModal('video', data)"
                      class=['link--accent']
                    )
                      span.d-flex.align-items-center
                        span.flex-shrink-0.d-flex.me-2
                          app-svg-icon(name="video")
                        span {{ userRole === 'admin' ? 'Видеозапись осмотра' : 'Просмотреть видеозапись' }}

                  .col-auto(v-if="data_detail.geo_location_lat >= 0 && data_detail.geo_location_long >= 0")
                    button(
                      type="button"
                      @click="type === 'map' ? showToMap(data) : openModal('map', data)"
                      class=['link--accent']
                    )
                      span.d-flex.align-items-center
                        span.flex-shrink-0.d-flex.me-2
                          app-svg-icon(name="map-point")
                        span {{ userRole === 'admin' ? 'На карте' : 'Посмотреть на карте' }}

</template>

<script>
import examsApi from '@/api/exams';

import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import variables from '@/helpers/variables';
import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';

import AppAccordion from '@/components/common/Accordion';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTooltip from '@/components/common/Tooltip';
import AppSignature from '@/components/profile/common/Signature';
import AppAvatar from '@/components/profile/common/Avatar';
import AppStatus from '@/components/profile/common/Status';

export default {
  name: 'AppExamCard',
  components: {
    AppStatus,
    AppAvatar,
    AppAccordion,
    AppSvgIcon,
    AppTooltip,
    AppSignature,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      required: false,
      default: 'exams',
    },
    parentId: {
      type: String,
      required: false,
      default: 'device-exams',
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      v: variables,
      data_detail: [],
      opened: false,
    };
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      isLoading: (state) => state.settings.isLoading,
      error: (state) => state.settings.error,
    }),
  },
  methods: {
    async onOpen() {
      this.opened = true;
      let examDetailData = await examsApi.getExamDetail(this.data.exams_id);
      this.data_detail = examDetailData.data;
      this.data['exam_video'] = this.data_detail['exam_video'];
      this.data.lat = this.data_detail['geo_location_lat'];
      this.data.long = this.data_detail['geo_location_long'];
      this.data.patient_avatar =
        this.data_detail.archive_patient_avatar || this.data_detail.patient_avatar;
    },
    trimNum(coords) {
      return Number(coords).toFixed(2);
    },
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
    openModal(slug, data) {
      this.$emit('openModal', slug, data);
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    showToMap(data) {
      const mainScroll = document.querySelector(
        `#${this.v.dom.mainScroll} .simplebar-content-wrapper`
      );
      if (mainScroll) mainScroll.scrollTop = 0;
      this.$emit('map', data);
    },
  },
};
</script>

<style lang="scss">
.profile-table {
  &.is-exams-table {
    .profile-table {
      &__header {
        padding-right: $spacer * 4;
      }
    }
  }
}
.exam-card {
  &__status {
    .filter-is-open & {
      justify-content: flex-end;
    }
  }
  &__header {
    min-height: 50px;
  }

  &__content {
    &-info {
      position: relative;
      color: #515f8f;

      &-item {
        padding-right: $spacer * 4;

        @include mediaToDown($xxl) {
          padding-right: 0;
        }
        & + .exam-card__content-info-item {
          border-top: $border-width solid get($colors, gray);
        }
      }
      &:before {
        @include pseudo-wrapper();
        left: 50%;
        transform: translate3d(-50%, 0, 0) scale3d(1.03, 1, 1);
        z-index: get($index, negative);
        background: get($gradients, blue-light);
        opacity: 0.4;
        border-radius: $border-radius-sm;
      }
    }
    &-footer {
      padding-right: $spacer * 4;

      @include mediaToDown($xxl) {
        padding-right: 0;
      }
      svg {
        fill: get($colors, blue);
      }
      .icon {
        &__video {
          height: $spacer * 2;
          width: $spacer * 2.5;
        }
        &__map-point {
          width: $spacer * 2;
          height: $spacer * 2;
        }
      }
    }
  }
  .accordion {
    padding: 0;

    &__header {
      padding: $spacer $spacer * 4 $spacer $spacer * 1.6;
      @include transition();

      &[aria-expanded='true'] {
        color: get($colors, #515f8f);

        .accordion {
          &__button {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
    &__button {
      top: 50%;
      right: $spacer * 1.6;
      transform: translateY(-50%);
    }
    &__body {
      padding: 0 $spacer * 3;
      overflow: hidden;
    }
  }
}
.yellowText {
  color: #ec9125;
}
.air-params {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
.air-params__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  color: #586387;
  position: relative;
  width: 24px;
  height: 27px;
  text-align: center;
  background: #fff;
  border-radius: 20px 20px 0 0;
  button {
    width: 24px;
    height: 27px;
    position: relative;
    z-index: 9999;
    svg {
      display: none;
    }
  }
  &:after {
    content: attr(vall);
    font-size: 10px;
    color: #586387;
    position: absolute;
  }
}
.air-params__item--water {
  background: #d9edf9;
  border-radius: 0 0 20px 20px;
}
.doctorSign {
  display: block;
  width: 25px;
  height: 25px;
  background: url('./../../../../assets/img/sign.svg') 50% 50% no-repeat;
}
</style>
