import axios from '@/api/axios';

const createPintGroup = (credentials) => axios.post(`/api/v2/print/create`, credentials);

const getAllPrintGroups = (page, order_by, credentials) =>
  axios.get(`/api/v2/print/get_all?page=${page}&per_page=10&order_by=${order_by}`, credentials);

const getPrintGroupDevices = (group_id, page, order_by, credentials) =>
  axios.get(
    `/api/v2/print/get/${group_id}?page=${page}&per_page=10&order_by=${order_by}`,
    credentials
  );

const addDevicePrintGroup = (credentials) => axios.post(`/api/v2/print/add_device`, credentials);

const updatePrintingGroup = (group_id, credentials) =>
  axios.put(`/api/v2/print/update/${group_id}`, credentials);

const deletePrintingGroup = (group_id) => axios.delete(`/api/v2/print/delete/${group_id}`);

export default {
  getAllPrintGroups,
  getPrintGroupDevices,
  createPintGroup,
  addDevicePrintGroup,
  updatePrintingGroup,
  deletePrintingGroup,
};
