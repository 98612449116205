import axios from '@/api/axios';

const createIncomingMedDocument = (credentials) => axios.post('/api/v1/incoming_med_document/create', credentials);

const acceptIncomingMedDocument = (med_doc_id, credentials) => axios.post(`/api/v2/med_doc/accept/${med_doc_id}`, credentials);

const archiveIncomingMedDocument = (med_doc_id, credentials) => axios.post(`/api/v1/incoming_med_document/archived/${med_doc_id}`, credentials);

const getIncomingMedDocument = (credentials) => axios.post('/api/v1/incoming_med_document', credentials);
const getIncomingMedDocumentV2 = (credentials) => axios.get(`/api/v2/med_doc/incoming_document_list?${credentials}`);


export default {
    createIncomingMedDocument,
    getIncomingMedDocument,
    acceptIncomingMedDocument,
    archiveIncomingMedDocument,
    getIncomingMedDocumentV2
};
