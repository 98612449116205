<template lang="pug">
section(:class="['profile-card', 'profile-card-device']")
  .row.align-items-center.g-4.flex-xl-nowrap.justify-content-between
    div(class=['col-auto col-md-3 col-xl-3 paddingLeft d-flex align-items-center'])
      .d-none.d-md-flex.flex-shrink-0.profile-card-device__icon
        app-svg-icon(name="printer" view-box="0 0 17 24")
      .ms-md-4
      div.d-flex.flex-column
        span {{ data.name }}
    .col-md-2.d-flex.justify-content-end
      div.me-4
        button(
          type="button"
          @click.prevent="changeModal"
          class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
          app-svg-icon(name="plus-thin")
      div
        button(
          type="button"
          @click.prevent="deleteModal"
          class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
          app-svg-icon(name="trash")
</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppAdminPrintingGroupCard',
  components: {
    AppSvgIcon,
    AppTitle,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteModal() {
      this.$emit('delete');
    },
    changeModal() {
      this.$emit('change');
    },
  },
};
</script>

<style scoped lang="scss">
.profile-card-device {
  @include mediaToDown($xxl) {
    padding-left: $spacer * 1.6;
    padding-right: $spacer * 1.6;
  }
  @include mediaToDown($xl) {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: $border-width solid get($colors, gray-hover);
    border-bottom: $border-width solid get($colors, gray-hover);
  }
  @include mediaToDown($md) {
    padding-left: 0;
    padding-right: 0;
  }
  &__icon {
    @include mediaToDown($xl) {
      background: get($colors, white);
    }
  }
}
</style>
