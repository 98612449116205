import axios from '@/api/axios';

const getUsers = (credentials) => axios.post('/api/v1/user', credentials);

const getUsersV2 = (credentials) => axios.get('/api/v2/users', credentials);

const getUsersNew = (credentials) => axios.get(`/api/v2/users/${credentials}`);

const getCurrentUser = (user_id) => axios.get(`/api/v2/users/${user_id}`);

const createUser = (credentials) => axios.post('/api/v2/users', credentials);

const getCountUsers = (credentials) => axios.post('/api/v1/user/count', credentials);

const getUsersExams = (user_id) => axios.get(`/api/v2/users/${user_id}/exams`);

const getUserDevices = (user_id) => axios.get(`/api/v2/users/${user_id}/devices`);

const changeUserEmail = (credentials) => axios.post('/api/v1/user/changeEmail', credentials);

const changeUserNames = (credentials) => axios.post('/api/v1/user/changeNames', credentials);

const changeUserPassword = (credentials) => axios.post('/api/v1/user/changePassword', credentials);

const changeUserAvatar = (credentials) => axios.post('/api/v1/user/setAvatar', credentials);

const forgotPassword = (credentials) => axios.post('/api/v1/user/forgotPassword', credentials);

const deleteUser = (id) => axios.delete(`/api/v1/user/${id}`);

const getUsersSuggestionList = (company_name) =>
  axios.get(`/api/v2/users/suggestion?page=0&per_page=10&company_name=${company_name}`);

export default {
  getUsers,
  getUsersV2,
  getUsersNew,
  getCurrentUser,
  getCountUsers,
  getUsersExams,
  getUserDevices,
  changeUserEmail,
  changeUserNames,
  changeUserPassword,
  changeUserAvatar,
  forgotPassword,
  deleteUser,
  createUser,
  getUsersSuggestionList,
};
