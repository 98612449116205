<template lang="pug">
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Обработка входящего документа
        .px-4
          form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
            .row.gy-2
              .col.mb-2
                app-select(
                  ref="selectStatus"
                  :type="'form'"
                  :placeholder="'Выберите тип документа'"
                  :options="typeOpts"
                  @select="selectStatus"
              )

              .col-14(v-if="v$.form.firstName")
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                    type="text"
                    v-model="rowData.document_name"
                    placeholder="Введите номер / название документа"
                  )
                ul.form__error
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                    type="text"
                    v-model="rowData.patient_full_name"
                    placeholder="Введите ФИО пациента"
                  )
                ul.form__error
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
              .col-14(v-if="v$.form.firstName")
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                    type="text"
                    v-model="rowData.med_org_name"
                    placeholder="Укажите организацию, выдавшую документ"
                  )
                ul.form__error
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
              .col-14
                label.form__label.text-color--blue Комментарий клиента
                  textarea(
                    class="form__field mt-2"
                    v-model="rowData.client_comment"
                    placeholder="Комментарий клиента "
                  )
              .col-7
                label.form__label.text-color--blue Ответ медработника
                  textarea(
                    class="form__field mt-2"
                    v-model="rowData.doctor_comment"
                    placeholder="Ответ медработника"
                  )
              .col-7
                label.form__label.text-color--blue Диагноз
                  textarea(
                    class="form__field mt-2"
                    v-model="rowData.diagnosis"
                    placeholder="Причина отказа"
                  )

              .col-7
                .text-color--blue.mb-2 Дата выдачи
                date-picker(
                  prefix-class="app"
                  placeholder="Дата выдачи документа"
                  format="DD.MM.YYYY"
                  v-model="rowData.issue_date"
                  :disabled-date="disableAfterToday"
                  value-type="YYYY-MM-DD"
              )
              .col-7
                .d-flex.mb-2
                  .form__caption.w-50.me-4 Срок действия
                  label.form__checkbox.d-flex.w-50
                    input(
                      type="checkbox"
                      name="user-type"
                      value="person"
                      v-model="rowData.expiration_date === null"
                      )
                    i.form__checkbox-icon.me-2
                    span.form__checkbox-caption Бессрочно
                date-picker(
                  prefix-class="app"
                  placeholder="Укажите дату действия документа"
                  format="DD.MM.YYYY"
                  v-model="rowData.expiration_date"
                  value-type="YYYY-MM-DD"
                  :disabled-date="disableExpiration"
                )
              .col-7
                .form__file.d-flex.align-items-center
                  input(
                    class="d-none"
                    type="file"
                    name="document"
                    accept="image/*"
                  )
                  div(v-if="rowData.file_link" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                    span.flex-shrink-0.me-3
                      app-svg-icon(name="image")
                    ul.flex-grow-1.overflow-hidden
                      li.form__file-name.text-color--black {{ rowData.file_link }}
                  .text-color--blue.text-size--small(v-else) {{ rowData.file_link }}
                  button(
                    class=['form__file-button', 'd-flex justify-content-end align-items-center']
                    @click="download([rowData.file_link])"
                  )
                    app-svg-icon(name="download")

              .col-7
                label.form__checkbox.d-flex.pt-4.mt-2
                  input(
                    type="checkbox"
                    name="user-type"
                    v-model="rowData.acc_risk_groups"
                  )
                  i.form__checkbox-icon.me-2
                  span.form__checkbox-caption Учитывать в группах риска
              .col-7.mt-25(v-if="showData===false")
                button(
                  type="submit"
                  @click="onReject"
                  :disabled="false"
                  class=['button', 'form__button','button', 'button--border-blue']
                )
                  span.button__content Отклонить
              .col-7.mt-25(v-if="showData===false")
                button(
                  type="submit"
                  @click="onSubmit"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Подтвердить

  //окно с выбором действия
  handler-select-modal(
    :is-visible="isSelectVisible"
    :data="rowData"
    @close="closeSelectModal"
    @reject="closeSelectRejected"
    @confirm="closeSelectSubmitted"
  )

</template>

<script>
import {mapState} from 'vuex';

import variables from '@/helpers/variables';
import {toLocalDate} from '@/helpers/utils';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import {EXIF} from 'exif-js';
import is from 'is_js';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import {mask} from 'vue-the-mask';
import AppSelect from '@/components/common/Select';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import HandlerSelectModal from '@/components/profile/admin/docs/HandlerSelectModal';
import {modalsMixin} from '@/mixins/modals';

import incomingMedDocs from '@/api/incomingMedDocs';

import Autocomplete from 'vuejs-auto-complete';

export default {
  name: 'AppCreatePatientsModal',
  directives: {
    mask,
  },
  components: {
    AppLoading,
    AppErrorMessage,
    AppValidationErrors,
    AppModal,
    AppTitle,
    AppSvgIcon,
    DatePicker,
    AppSelect,
    AppMultipleSelect,
    HandlerSelectModal,
    Autocomplete,
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    rowData: {
      required: true,
      type: Object,
    },
    changeDocStatus: {
      required: false,
      type: Function,
    },
    showData: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      isSelectVisible: false,
      patientsSelected: [],
      isModalVisible: {
        success: false,
      },
      form: {
        firstName: '',
        signature: null,
        file: null,
      },
      activeTab: 'one',
      localValidationErrors: null,

      signature: {
        localUrl: null,
      },
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        defaultValue: {
          panel: 'year',
          year: '1980',
        },
      },
      successData: null,
      comment: '',
      typeOpts: [
        {
          caption: 'справка о состоянии здоровья',
          icon: 'allright',
          value: 'health_certificate',
          selected: false,
        },
        {
          caption: 'справка о травме',
          icon: 'allright',
          value: 'injury_certificate',
          selected: false,
        },
        {
          caption: 'справка о результатах ХТИ',
          icon: 'allright',
          value: 'hti_certificate',
          selected: false,
        },
        {
          caption: 'заключение предварительного или периодический мед. осмотра',
          icon: 'allright',
          value: 'medical_exam_conclusion',
          selected: false,
        },
        {
          caption: 'акт медицинского освидетельствования',
          icon: 'allright',
          value: 'medical_exam_certificate',
          selected: false,
        },
        {
          caption: 'официальное обращение клиента',
          icon: 'allright',
          value: 'client_request',
          selected: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
      settings: (state) => state.settings.data,
    }),
    isOnePatientTab() {
      return this.activeTab === 'one';
    },
    defaultDate() {
      return new Date().setYear(this.datepicker.defaultValue.year);
    },
  },
  watch: {
    isVisible: {
      handler(newValue, oldValue) {
        if (newValue === true && oldValue === false) {
          for (let optId in this.typeOpts) {
            if (this.typeOpts[optId].value === this.rowData.document_type) {
              this.typeOpts[optId].selected = true;
            } else {
              this.typeOpts[optId].selected = false;
            }
          }
          this.patientsSelected = [this.rowData.patient_code];
        }
      },
    },
  },
  validations() {
    if (this.isOnePatientTab) {
      return {
        form: {
          firstName: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          file: {
            required,
          },
        },
      };
    }
  },
  methods: {
    //form
    selectStatus(option) {
      this.typeOpts.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.status = true;
      this.form.status = option.value;
    },
    updateSelectedPatients(data) {
      this.form.isDirty.patients = data.length > 0;
      this.form.patients = data.map((el) => el.value);
      if (data.length) {
        if (is.null(this.form.negative.patients)) this.form.negative.patients = false;
      } else this.form.negative.patients = null;
      this.getCount();
    },

    //get data
    sendData() {
      let acc_risk_groups = this.rowData.acc_risk_groups;
      if (acc_risk_groups === null) {
        acc_risk_groups = false;
      }
      let expiration_date = this.rowData.expiration_date;
      if (expiration_date !== null) {
        expiration_date = this.$moment(expiration_date).format('YYYY-MM-DD');
      }
      let issue_date = this.rowData.issue_date;
      if (issue_date !== null) {
        issue_date = this.$moment(issue_date).format('YYYY-MM-DD');
      }
      return {
        document_type: this.rowData.document_type,
        client_comment: this.rowData.client_comment,
        doctor_comment: this.rowData.doctor_comment,
        diagnosis: this.rowData.diagnosis,
        document_name: this.rowData.document_name,
        med_org_name: this.rowData.med_org_name,
        document_status: 'rejected',
        expiration_date: expiration_date,
        issue_date: issue_date,
        acc_risk_groups: acc_risk_groups,
      };
    },

    //reject / submit no change borders
    async onReject() {
      let data = this.sendData();
      data['document_status'] = 'rejected';
      await incomingMedDocs.acceptIncomingMedDocument(this.rowData.id, data);
      this.$toast.success('Заявка отклонена');
      this.close();
      this.changeDocStatus();
    },
    async onSubmit() {
      if (this.rowData.document_type !== 'health_certificate') {
        let data = this.sendData();
        data['document_status'] = 'confirmed';
        await incomingMedDocs.acceptIncomingMedDocument(this.rowData.id, data);
        this.changeDocStatus();
        this.close();
        this.$toast.success('Заявка подтверждена');
      } else {
        this.isSelectVisible = true;
      }
    },

    //update borders
    async closeSelectRejected() {
      this.isSelectVisible = false;
      let data = this.sendData();
      data['document_status'] = 'confirmed';
      await incomingMedDocs.acceptIncomingMedDocument(this.rowData.id, data);
      this.changeDocStatus();
      this.close();
      this.$toast.success('Заявка подтверждена без обновления границ');
    },
    async closeSelectSubmitted(bordersData) {
      let updatedBorders = bordersData;
      this.isSelectVisible = false;
      updatedBorders['document_status'] = 'confirmed';
      await incomingMedDocs.acceptIncomingMedDocument(this.rowData.id, updatedBorders);
      this.changeDocStatus();
      this.close();
      this.$toast.success('Заявка подтверждена');
    },

    //common
    getMetaDataImage(file, variable) {
      if (file) {
        EXIF.getData(file, () => {
          this[variable].exifdata = file.exifdata;
        });
      }
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },

    //datepickers
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.rowData.expiration_date) {
        return date > today;
      } else if (this.rowData.expiration_date < today) {
        return date > this.rowData.expiration_date;
      } else {
        return date > today;
      }
    },
    disableExpiration(date) {
      return date < this.rowData.issue_date;
    },
    disableBeforeAdult(date) {
      const today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      // today.setMonth(8);
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    disableAfterTodayDrive(date) {
      const start = new Date(1930, 1, 1);
      start.setHours(0, 0, 0, 0);
      return date < start;
    },

    //get files
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    onSignatureSelected({target}) {
      const reader = new FileReader();
      this.form.signature = target.files[0];
      reader.readAsDataURL(this.form.signature);
      reader.onload = () => (this.signature.localUrl = reader.result);
    },
    download(urls) {
      for (let i = 0; i < urls.length; i++) {
        if (urls[i]) {
          var frame = document.createElement('iframe');
          frame.src = urls[i];
          frame['download'] = 1;
          document.body.appendChild(frame);
        }
      }
    },

    //clear
    clearSignatureInput() {
      this.form.signature = null;
      if (this.$refs.signatureInput) this.$refs.signatureInput.value = '';
    },
    clearFileInput() {
      this.form.file = null;
      if (this.$refs.fileInput) this.$refs.fileInput.value = '';
    },
    resetForm() {
      this.v$.$reset();
      for (let key in this.form) {
        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
          this.form[key] = '';
        }
      }
      this.form.gender = '1';
      this.localValidationErrors = null;
      this.clearFileInput();
      this.clearSignatureInput();
    },

    //
    open() {
      this.isSelectVisible = true;
    },
    close() {
      this.resetForm();
      this.$emit('close');
    },
    closeSelectModal() {
      this.isSelectVisible = false;
    },
    success(data) {
      if (!this.isOnePatientTab) this.successData = data;
      this.openModal('success');
      this.$emit('success');
    },
    backToForm() {
      this.closeSuccessModal();
      this.$emit('open');
    },
  },
};
</script>
