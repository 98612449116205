<template lang="pug">
.profile-doctors.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7.col-md-auto
        app-title(:type="'large'") Журнал отстраненных работников
      .col.col-md-auto.ms-md-auto
        transition(name="translucent")
              button(
                type="button"
                @click="exportSuspendedWorkers(filters)"
                class=['link--accent']
              )
                .d-flex.align-items-center
                span.flex-shrink-0.me-3
                    app-svg-icon(name="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}

  .profile-doctors__content.position-relative.mt-25
    transition(name="translucent")
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      div(v-if="suspendedWorkers")
        template(v-if="suspendedWorkers.result && suspendedWorkers.result.length")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.g-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    type="button"
                    @click="sortingSuspendedWorkers(column.num)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span.text-overflow(:title="column.caption") {{ column.caption }}
                      span.d-flex.ms-3
                        app-svg-icon(name="down-arrow")

            ul.profile-table__body
              li.profile-table__row(v-for="suspendedWorker in suspendedWorkers.result" :key="suspendedWorker.id")
                section(
                  class=['user-card', 'profile-card']
                )
                  .row.align-items-center.g-4.gy-xl-0

                    .col-auto
                      span.idDoc {{ suspendedWorker.id }}

                    .col-2
                      div {{$moment(suspendedWorker.admission_datetime).format('DD.MM.YYYY')}}  {{$moment(suspendedWorker.admission_datetime).format('HH:mm:ss')}}

                    .col-2(v-if='isAdmin')
                      div {{ suspendedWorker.patient_company_name }}

                    .col-2
                      div {{ suspendedWorker.patient_full_name }}

                    .col-4
                      div {{ suspendedWorker.ra_and_rm_pre_diagnosis.join(" ") }}

                    .col-2
                      div
                        span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='download(suspendedWorker.array_links_file)')
                          img(src="@/assets/img/notice.svg")

        .d-flex.justify-content-center.mt-5(v-if='suspendedWorkers.result && suspendedWorkers.result.length > 0')
          app-pagination(
            :total="suspendedWorkers.total"
            :limit="limit"
            :current-page="currentPage"
            :url='baseUrl'
          )

        template(v-else)
          .text-color--blue.text-size--small Отстраненные работники не найдены

        // filter
        filter-suspended-workers(
          ref="filter"
          :options="filterOptions"
          :is-open="isFilterOpen"
          :is-filtered="isFiltered"
          :typeAction="type"
          @getCount="getCountFilter"
          @open="openFilter"
          @clearClose="clearClose"
          @clear="clearFilter"
          @submit="submitFilter"
          @closeFilter="clearClose"
        )
</template>

<script>
import {mapState} from 'vuex';
import {mapGetters} from 'vuex';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import {blobToFile} from '@/helpers/utils';
import {toLocalDate, toLocalTime} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';

import AppPagination from '@/components/common/Pagination';

import FilterSuspendedWorkers from './FilterSuspendedWorkers.vue';

import suspendedWorkers from '@/api/suspendedWorkers';

export default {
  name: 'SuspendedWorkersDashboard',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppPagination,
    FilterSuspendedWorkers,
  },
  computed: {
    baseUrl() {
      return this.$route.path;
    },
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    isAdmin() {
      return this.userRole === 'admin';
    },
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  data() {
    return {
      limit: 10,
      isFilterOpen: false,
      sortKey: 'datetime',
      sortInverse: true,
      columns: [
        {
          num: 0,
          class: 'col-auto',
          caption: '№',
          key: 'id',
          inverse: false,
        },
        {
          num: 1,
          class: 'col-2',
          caption: 'Дата и время',
          key: 'admission_datetime',
          inverse: false,
        },
        {
          num: 2,
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          num: 3,
          class: 'col-6',
          caption: 'Предварительный диагноз',
          key: 'ra_and_rm_pre_diagnosis',
          inverse: false,
        },
      ],
      suspendedWorkers: [
        {
          id: 0,
          datetime: '10.02.2023',
          username: 'Арбузов Федор',
          diagnosis: 'все плохо',
          link: 'https://google.com',
        },
      ],
      filters: {},
    };
  },
  watch: {
    async currentPage() {
      const result = await suspendedWorkers.suspendedWorkers({
        filters: this.filters,
        paging_params: {
          limit: this.limit,
          offset: this.currentPage * this.limit - this.limit,
        },
      });
      this.suspendedWorkers = result.data;
    },
  },
  async created() {
    const result = await suspendedWorkers.suspendedWorkers({
      paging_params: {
        limit: this.limit,
        offset: this.currentPage * this.limit - this.limit,
      },
    });
    this.suspendedWorkers = result.data;
    if (this.isAdmin) {
      this.columns = [
        {
          num: 0,
          class: 'col-auto',
          caption: '№',
          key: 'id',
          inverse: false,
        },
        {
          num: 1,
          class: 'col-2',
          caption: 'Дата и время',
          key: 'admission_datetime',
          inverse: false,
        },
        {
          num: 2,
          class: 'col-2',
          caption: 'Компания',
          key: 'patient_company_name',
          inverse: false,
        },
        {
          num: 3,
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          num: 4,
          class: 'col-4',
          caption: 'Предварительный диагноз',
          key: 'ra_and_rm_pre_diagnosis',
          inverse: false,
        },
      ];
    }
  },
  methods: {
    clearClose() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
    async exportSuspendedWorkers() {
      suspendedWorkers
        .suspendedWorkersExport({
          filters: this.filters,
        })
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    async submitFilter(data) {
      this.filters = data.filters;
      const result = await suspendedWorkers.suspendedWorkers({
        filters: this.filters,
        paging_params: {
          limit: this.limit,
          offset: 0,
        },
      });
      this.suspendedWorkers = result.data;
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
    async sortingSuspendedWorkers(num) {
      let column = this.columns[num];
      this.columns[num].inverse = !this.columns[num].inverse;
      this.sortKey = column.key;
      this.sortInverse = column.inverse;
      const result = await suspendedWorkers.suspendedWorkers({
        filters: this.filters,
        paging_params: {
          limit: this.limit,
          offset: 0,
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      });
      this.suspendedWorkers = result.data;
    },

    //скачать все справки
    async download(urls) {
      try {
        for (const url of urls) {
          await this.downloadFile(url);
        }
      } catch (error) {
        console.error('Ошибка при скачивании файлов:', error);
      }
    },

    async downloadFile(url) {
      try {
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.download = '';
        document.body.appendChild(iframe);

        iframe.onload = () => {
          document.body.removeChild(iframe);
        };
      } catch (error) {
        console.error(`Ошибка при скачивании файла (${url}):`, error);
      }
    },
  },
};
</script>
