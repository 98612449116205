import axios from '@/api/axios';

const getAutoBotSettings = () => axios.get('/api/v2/autobot?per_page=30');

const setAutoBotSettings = (data) => axios.put('/api/v2/autobot', data);

const createProjectSetup = (credentials) => axios.post('/api/v2/project_settings/', credentials);

const uploadFile = (fileLink, file) => axios.put(fileLink, file);

const getLastSettingsByTypeName = (typeName) => axios.get(`/api/v2/project_settings/last/${typeName}`);

export default {
  getAutoBotSettings,
  setAutoBotSettings,
  createProjectSetup,
  uploadFile,
  getLastSettingsByTypeName
};
