<template lang="pug">
.inspection-card__table.col-14
  .row
    .col-14
      .table__header.mb-4.d-flex.flex-xl-row.flex-column.title.title--small(
          @click="getLastExams(); openCard()"
          class=['text-color--blue-dark', 'mb-2', 'mb-xl-0', 'block-history-title']
        ) история осмотров
        span.d-flex.align-items-center.ms-3
          app-svg-icon(name="down-arrow" :class='{rotate: isActiveIcon}')

      .position-relative
        transition(name="translucent")
          app-dashboard-card(
            v-if="showLastExams"
            :goTo="'exams'"
            :classes="`${lastExams && lastExams.length === 0 ? 'is-empty' : ''}`"
          )

            template(v-slot:body)
              template(v-if="lastExams.length && !isLoading")
                .profile-table.d-none.d-xl-block
                  .profile-table__header
                    .row.align-items-center.g-4
                      div(v-for="column in columns" :key="column.key" :class="column.class")
                        button(
                          v-if="column.caption || column.icon"
                          type="button"
                          class=['profile-table__sort-button']
                        )
                          span.d-flex.align-items-center
                            span(v-if="column.caption") {{ column.caption }}
                            span.flex-shrink-0(v-else-if="column.icon")
                              app-svg-icon(:name="column.icon")
                            span.flex-shrink-0.d-flex.ms-3
                              app-svg-icon(name="down-arrow" )

                  ul.profile-table__body(id="patient-last-exams")
                    li.profile-table__row(v-for="lastExam in lastExams" :key="lastExam.exams_id")
                      app-exam-card(
                        :data="lastExam"
                        :parent-id="'patient-last-exams'"
                        :columns="columns"
                        :classes="'d-none d-xl-block'"
                        @openModal="openExamDetailModal"
                        @openCertificate="openCertificate"
                        @openGallery="openGallery"
                      )
              .text-size--small.text-color--gray-light(v-if="!lastExams.length && !isLoading") История осмотров отсутствует

      //certificate modal
    app-certificate-modal(
        :is-visible="isModalVisible.certificate"
        :data="certificate"
        @close="closeModal('certificate')"
    )

        // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )

    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
</template>

<script>
import AppTooltip from '@/components/common/Tooltip';
import AppSvgIcon from '@/components/common/SvgIcon';
import examsApi from '@/api/exams';

import AppDashboardCard from '@/components/profile/common/dashboard/DashboardCard';
import AppExamCard from '@/components/profile/common/exams/ExamCard';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Pagination} from 'swiper';
SwiperCore.use([Pagination]);

import moment from 'moment';

import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {modalsMixin} from '@/mixins/modals';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'DoctorExamCardMedDocsHistory',
  components: {
    AppSvgIcon,
    AppTooltip,
    AppDashboardCard,
    AppExamCard,
    Swiper,
    SwiperSlide,
    SwiperCore,
    Pagination,
    AppModal,
    AppGallery,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppCertificateModal,
  },
  mixins: [
    examDetailModalMixin,
    examDetailMobileMixin,
    galleryMixin,
    certificateModalMixin,
    modalsMixin,
  ],
  props: {
    exam: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isActiveIcon: false,
      sortKey: 'exam_number',
      showLastExams: false,
      lastExams: {},
      columns: [
        {
          class: 'col-4 col-xxl-2 col-sl-3 col-xl-3',
          caption: 'Дата',
          key: 'exam_datetime',
          inverse: true,
        },
        {
          class: 'col-3 col-xxl-4',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1 col-sl-2 col-xl-2',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-1',
          key: 'health_complaint',
        },
        {
          class: 'col-2 ms-auto',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
    };
  },
  methods: {
    openCard() {
      this.isActiveIcon = !this.isActiveIcon;
      this.showLastExams = !this.showLastExams;
    },
    async getLastExams() {
      let params = 'true';
      let order_by = `-exam_datetime`;
      let order_by_number = '-exam_number';
      let currentExamTime = moment(this.exam.exam_datetime).format('YYYY-MM-DD hh:mm:ss');
      if (!this.lastExams.length) {
        this.isLoading = true;
      }
      let res = await examsApi.getHistoryExamsByPatientCode(
        this.exam.patient_code,
        6,
        params,
        currentExamTime,
        order_by,
        order_by_number
      );
      this.exams = res.data.items;
      this.lastExams = this.exams.slice(1, 6);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.inspection-card__table {
  .ms-3 {
    width: 10px;
  }
  margin-left: auto;

  .profile-table__header {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
    @include font-size(get($text-sizes, small));
  }
  .rotate {
    transform: rotate(-180deg);
  }
}
</style>
