<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" :classes="`modal-refuse`")
    template(v-slot:body)
      .row.justify-content-center.align-items-center
        .col-12
          .text-center.pt-4
            app-title(:type="'large'" ) {{ getTitle }}
            p.text-color--blue.mt-3.text-uppercase {{ data.patient_full_name }}
      .row.gx-5.justify-content-center.mb-4.mt-3
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="pulse")
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_high_alarm}') {{ data.bp_high }}/
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="cardio")
            span(v-if="data.heart_rate" :class='{yellowText: data.heart_rate_alarm}') {{ data.heart_rate }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="temp")
            span(v-if="data.body_temp" :class='{yellowText: data.body_temp_alarm}') {{ data.body_temp + "\u2103" }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="breathalyzer")
            span(v-if="data.alcohol >= 0" :class='{yellowText: data.alcohol_alarm}') {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
            span(v-else) &#8212;

      .row.g-3
        .col-12
        .refuseCauseHeader.mt-2.mb-2.text-color--blue-dark Причина
        .col-12
          .row.g-2
            template(v-for='reason in reasons')
              template(v-if='reason.left || reason.right')
                .col-auto(v-if='reason.both == null && reason.left && reason.right')
                  .refuseCause Травма: {{reason.title}}, слева, справа
                .col-auto(v-if='reason.both')
                  .refuseCause Травма: {{reason.title}}
                .col-auto(v-if='reason.left && !reason.right')
                  .refuseCause Травма: {{reason.title}}, слева
                .col-auto(v-if='reason.right && !reason.left')
                  .refuseCause Травма: {{reason.title}}, справа
              template(v-else)
                .col-auto(v-if='reason.title !== "Травма"')
                  .refuseCause {{reason.title}}
      .row
        .col-14
          textarea.refuseDesc(v-model="reasonText" placeholder="При необходимости укажите дополнительную причину отстранения")
      .d-flex.justify-content-center.mt-4
        button(
          @click="sendResultData('success')"
          type="button"
          class=['button button--accent w-100']
        )
          span.button__content Подтвердить
</template>

<script>
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
export default {
  name: 'addDoctorReasonsRefuse',
  data() {
    return {
      reasonText: '',
    };
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object,
    },
    reasons: {
      required: true,
      type: Array,
    },
    type: {
      type: String,
    },
    time: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  components: {
    AppModal,
    AppTitle,
    AppSvgIcon,
  },
  computed: {
    getTitle() {
      if (
        this.type == '1no' ||
        this.type == '2no' ||
        this.type == '3no' ||
        this.type == '4no' ||
        this.type == '5no'
      ) {
        return 'подтвердите отстранение осмотра';
      } else {
        return 'подтвердите прохождение осмотра';
      }
    },
  },
  methods: {
    sendResultData() {
      this.$emit('closeReasons');
      this.$emit('close');
      this.$emit('setRefuseText', this.reasonText);
      this.$emit('sendReasons');
    },
    closeModal() {
      this.$emit('close');
    },
  },
  watch: {
    time() {
      this.reasonText = '';
    },
  },
};
</script>
<style lang="scss">
.yellowText {
  color: #ec9125;
}
.refuseCauseHeader {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 14px;
  color: #000000;
}
.refuseCause {
  font-size: 14px;
  color: #fff;
  background: #59658d;
  border-radius: 4px;
  padding: 4px 14px;
}
.modal-refuse {
  width: 771px;
}

.refuseDesc {
  border: 1px solid #dfe4f8;
  border-radius: 6px;
  resize: none;
  width: 100%;
  height: 66px;
  margin-top: 20px;
  padding: 14px;
  &::placeholder {
    color: #c5cbe1;
    font-size: 14px;
  }
}
</style>
