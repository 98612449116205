<template lang="pug">
.profile-devices__inner.is-filterable
  .profile-devices__header
    .row.align-items-center
      .col-14.col-md-auto.order-last.order-md-first
        app-title(:type="'large'" :id="'isAnchorForBtn'") Группы печати талонов
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
          .d-flex.justify-content-end
            button(
              type="button"
              @click="createPrintingGroup"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить группу
      .col-12
        NavDevice

  .profile-devices__content.mt-20.position-relative
    div
      template(v-if="allPrintGroups.length")
        .profile-table
          .profile-table__header.d-none.d-xl-block
            .row.align-items-center
              div(v-for="column in columns" :key="column.key" :class="column.class")
                button(
                  v-if="column.caption || column.icon"
                  type="button"
                  class=['profile-table__sort-button']
                  @click="SortingPrintingGroups(column.key)"
                  :class="{'is-active': sortKey === column.key}"
                )
                  span.d-flex.align-items-center
                    span {{ column.caption }}
                    span.d-flex.ms-3
                      app-svg-icon(name="down-arrow")

          ul.profile-table__body
            li.profile-table__row(v-for="printGroup in allPrintGroups")
              router-link(:to="{ name: 'printing-group', params: { id: printGroup.id }, query: { additionalData: printGroup.name } }" class="d-block")
                section.profile-card
                  .row.align-items-md-center.gy-4.gy-md-0.ms-0
                    .col-8
                      .text-color--blue.mb-2.d-xl-none
                      span(v-if="printGroup.name") {{ printGroup.name }}
                      span(v-else) &#8212;
                    .col-3
                      .text-color--blue.mb-2.d-xl-none
                      span(v-if="printGroup.total_devices") {{ printGroup.total_devices }}
                      span(v-else) &#8212;
                    .col-md-2.d-flex.justify-content-end
                      button(
                        type="button"
                        @click.prevent="deletePrintingGroup(printGroup.id)"
                        class=['profile-card__button profile-card__button--delete no-border', 'd-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                        app-svg-icon(name="trash")

          .d-flex.justify-content-center.align-items-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

      template(v-else)
        .text-color--blue.text-size--small Группы печати талонов не найдены

  // Add printing group
  app-add-printing-group-modal(
    :is-visible="isModalVisible.createGroup"
    @close="closeModal('createGroup')"
    @success="openModal('successCreated')"
    @updateData='getAllPrintingGroups'
  )

  // Edit printing group
  app-edit-printing-group-modal(
    :data="printGroupData"
    :is-visible="isModalVisible.editPrintingGroup"
    @close="closeModal('editPrintingGroup')"
    @success="openModal('successEdited')"
    @updateData='getAllPrintingGroups'
  )

    // delete modal
  app-delete-printing-group-modal(
    :groupId="groupId"
    :is-visible="isModalVisible.deletePrintingGroup"
    @close="closeModal('deletePrintingGroup')"
    @success="openModal('successDeleted')"
    @updateData='getAllPrintingGroups'
  )

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successCreated" @close="closeModal('successCreated')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Группа печати талонов успешно создана
            p.text-size--small.text-color--blue.mt-3

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeModal('successCreated')"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад

  // success modals
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successEdited" @close="closeModal('successEdited')" :without-close-btn="true")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Успешно изменено!

        .d-flex.justify-content-center.mt-5
          button(
            @click="closeModal('successEdited')"
            type="button"
            class=['button button--accent']
          )
            span.button__content Закрыть

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successDeleted" @close="closeModal('successDeleted')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Группа печати талонов успешно удалена
          p.text-size--small.text-color--blue.mt-3

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeModal('successDeleted');"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад
</template>

<script>
import printGroupApi from '@/api/printGroup';

import NavDevice from '@/components/profile/common/NavDevice';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAddPrintingGroupModal from '@/components/profile/admin/devices/AddPrintingGroupModal';
import AppEditPrintingGroupModal from '@/components/profile/admin/devices/AppEditPrintingGroupModal';
import AppDeletePrintingGroupModal from '@/components/profile/admin/devices/AppDeletePrintingGroupModal';
import AppAdminPrintingGroupDetail from '@/components/profile/admin/devices/AdminPrintingGroupDetail';
import AppModal from '@/components/common/Modal';
import AppPagination from '@/components/common/Pagination';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppAdminDevicesPrintingGroup',
  components: {
    NavDevice,
    AppTitle,
    AppSvgIcon,
    AppAddPrintingGroupModal,
    AppEditPrintingGroupModal,
    AppDeletePrintingGroupModal,
    AppAdminPrintingGroupDetail,
    AppModal,
    AppPagination,
  },
  mixins: [modalsMixin],
  data() {
    return {
      //data
      allPrintGroups: {},
      printGroupData: {},
      groupId: null,
      //tabs
      activeTabId: 'exams',
      columns: [
        {
          class: 'col-8',
          caption: 'Наименование группы',
          key: 'name',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Количество устройств',
          key: 'total_devices',
          inverse: true,
        },
      ],
      //modals
      isModalVisible: {
        createGroup: false,
        editPrintingGroup: false,
        deletePrintingGroup: false,
        successDeleted: false,
        successCreated: false,
        successEdited: false,
      },
      //sorting pagination
      pagination: {
        limit: 5,
        total: 0,
      },
      sortKey: 'name',
      sortInverse: true,
    };
  },
  computed: {
    baseUrl() {
      return this.$route.path;
    },
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
  },
  watch: {
    currentPage() {
      this.getAllPrintingGroups();
    },
  },
  created() {
    this.getAllPrintingGroups();
  },
  methods: {
    //get data
    async getAllPrintingGroups() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let result = await printGroupApi.getAllPrintGroups(this.currentPage - 1, order_by);
      this.allPrintGroups = result.data.items;
      this.pagination.total = result.data.total;
      this.pagination.limit = result.data.per_page;
    },

    //create edit delete
    createPrintingGroup() {
      this.isModalVisible.createGroup = true;
    },
    editPrintingGroup(printGroup) {
      this.printGroupData = printGroup;
      this.isModalVisible.editPrintingGroup = true;
    },
    deletePrintingGroup(id) {
      this.groupId = id;
      this.isModalVisible.deletePrintingGroup = true;
    },

    //sorting
    SortingPrintingGroups(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getAllPrintingGroups();
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .profile-card {
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    padding: 0;
  }
}
</style>
