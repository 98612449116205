<template lang="pug">
    .profile-doctors
      .profile-doctors__header
        .row.align-items-center
          .col-14.col-md-7
            .d-flex.align-items-center
              app-title(:type="'large'") Группы риска
        .col-14.d-none.d-md-block
          .d-flex.download-risks-groups

            .col-2.date-picker-margins
              date-picker(
                prefix-class="app"
                placeholder="от"
                format="DD.MM.YYYY"
                lang="ru"
                :editable="true"
                v-model="startDate"
                :disabled-date="disableAfterToday"
              )

            .col-2.date-picker-margins
              date-picker(
                prefix-class="app"
                placeholder="до"
                format="DD.MM.YYYY"
                lang="ru"
                :editable="true"
                v-model="endDate"
                :disabled-date="disableExpiration"
              )

            .col-3.date-picker-margins(v-if="currentUser.role==='ADMIN'")
              app-multiple-select(
                ref="multipeSelectUsers"
                :multiple="false"
                :results-view="'detail'"
                :search-type="'users'"
                :placeholder="'Введите название клиента'"
                @update="updateSelectedUsers"
              )

            .col-3
              button(
                type="button"
                @click="downloadRisksGroups"
                class=['button button--border-blue']
              )
                span.button__content
                  span  Скачать группы риска
  </template>

<script>
import {blobToFile} from '@/helpers/utils';
import risksGroups from '@/api/risksGroup';
import DatePicker from 'vue2-datepicker';
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';

export default {
  name: 'RiskGroupsDashboard',
  components: {
    AppTitle,
    DatePicker,
    AppMultipleSelect,
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      user: null,
    };
  },
  methods: {
    //datepicker
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.endDate) {
        return date > today;
      } else if (this.endDate < today) {
        return date > this.endDate;
      } else {
        return date > today;
      }
    },

    disableExpiration(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        return date < today;
      } else {
        return date < this.startDate;
      }
    },

    updateSelectedUsers(data) {
      this.user = data[0].value;
    },
    async downloadRisksGroups() {
      let data = {};
      if (this.startDate !== null) {
        data['date_from'] = this.$moment(this.startDate).format('DD.MM.YYYY');
      }
      if (this.endDate !== null) {
        data['date_to'] = this.$moment(this.endDate).format('DD.MM.YYYY');
      }
      if (this.user !== null) {
        data['users'] = {
          negative: false,
          payload: [this.user],
        };
      }
      if (this.currentUser.role === 'CLIENT') {
        data['users'] = {
          negative: false,
          payload: [this.currentUser.id],
        };
      }
      risksGroups
        .getRisksGroup({
          filters: data,
        })
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
  },
};
</script>

<style>
.download-risks-groups {
  margin-top: 50px;
}

.date-picker-margins {
  margin-right: 20px;
  margin-top: 2px;
}
</style>
