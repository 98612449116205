<template lang="pug">
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__header
    .row.align-items-center
      .col-14.col-md-auto.order-last.order-md-first
          app-title(:type="'large'" :id="'isAnchorForBtn'") {{ isTouchDevice ? 'Все устройства' : 'Устройства' }}
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
          router-link(v-if="devices && devices.length" :to="{name: 'map'}" class=['d-flex align-items-center', 'link--accent'])
            span.flex-shrink-0.me-2
              app-svg-icon(name="map-point")
            span.text-size--small {{ isTouchDevice ? 'Геокарта' : 'Просмотреть геокарту' }}

        .col-auto
          .d-flex.flex-column
            button(
              v-if="devices && devices.length"
              type="button"
              @click="exportDevices()"
              class=['link--accent']
            )
              span.d-flex.align-items-center
                span.flex-shrink-0.me-3
                  app-svg-icon(name="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}
      .col-12
        NavDevice

  .profile-devices__content.mt-20.position-relative
      transition(name="translucent")
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      div(v-if="devices")
        template(v-if="devices && devices.length")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    v-if="column.caption || column.icon"
                    type="button"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key && column.key !== 'attestation' && column.key !== 'device_verification_owner'}"
                    @click="sortingDevices(column.key, column.inverse)"
                  )
                    span.d-flex.align-items-center
                      span {{ column.caption }}
                      span.d-flex.ms-3
                        app-svg-icon(name="down-arrow")

            ul.profile-table__body
              li.profile-table__row(v-for="device in devices" :key="device.serial")
                router-link(:to="{name: 'device-detail', params: {id: device.serial}}" class=['d-block'])
                  section.profile-card
                    .row.align-items-md-center.gy-4.gy-md-0
                      .col-3
                        .text-color--blue.mb-2.d-xl-none Серийный номер
                        span(v-if="device.serial") {{ device.serial }}
                        span(v-else) &#8212;
                      .col-2.col-md-2
                        .d-flex.align-items-center.justify-content-end.justify-content-md-start
                          span(v-if="device.creation_date") {{ toLocalDate(device.creation_date) }}
                          span(v-else) &#8212;
                      .col-2
                        .text-color--blue.mb-2.d-xl-none Владелец
                        span(v-if="device.company_name") {{ device.company_name }}
                        span(v-else-if="device.device_owner_full_name") {{ device.device_owner_full_name }}
                        span(v-else) &#8212;

                      .col-2
                        .text-color--blue.mb-2.d-xl-none Контроль ПО
                        span(v-if="device.device_integrity === null") &#8212;
                        span(v-if="device.device_integrity === true") Пройден
                        span.text-color--orange(v-if="device.device_integrity === false") Не пройден
                      .col-2
                        .text-color--blue.mb-2.d-xl-none Поверка
                        span.text-color--orange(v-if="device.attestation === 'attestation_ends'") Заканчивается
                        span.text-color--orange(v-if="device.attestation === 'not_attestation'") Не поверено
                        span.text-color--blue-dark(v-if="device.attestation === 'attestation'") Поверено
                      .col-3
                        .d-flex.align-items-center
                          .text-color--blue.mb-2.d-xl-none Корпус
                          span(v-if="device.case_opened == null")  &#8212;
                          span(v-if="device.case_opened == false") Не вскрыт
                          span.text-color--orange(v-if="device.case_opened == true") Вскрыт
                          span.text-color--accent.ms-auto.flex-shrink-0.d-none.d-md-inline
                            app-svg-icon(name="right-arrow")

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              app-svg-icon(name="wrong")
            span Нет устройств

  // filter
  app-admin-devices-filter(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @open="openFilter"
    @close="closeFilter"
    @clear="clearFilter"
    @submit="submitFilter"
    @getCount="getCountFilter"
  )

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import devicesApi from '@/api/devices';

import variables from '@/helpers/variables';
import {declOfNum, blobToFile, toLocalDate} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';

import AppFilter from '@/components/profile/common/Filter';
import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';
import AppAdminDevicesFilter from '@/components/profile/admin/devices/AdminDevicesFilter';

import {modalsMixin} from '@/mixins/modals';
import {filterMixin} from '@/mixins/filter';

import NavDevice from '@/components/profile/common/NavDevice';

export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    AppFilter,
    AppTitle,
    AppSvgIcon,
    AppPagination,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    NavDevice,
    AppAdminDevicesFilter,
  },
  mixins: [modalsMixin, filterMixin],
  data() {
    return {
      devices: {},
      filterString: '',
      resultFilter: '',
      resultFilterForLoad: '',
      v: variables,
      declOfNum,
      filterOptions: {
        date: true,
        devices: true,
        methods: {
          getData: ['getDevices'],
          getCount: 'getCountDevices',
        },
      },
      sortKey: 'creation_date',
      columns: [
        {
          class: 'col-3',
          caption: 'Серийный номер',
          key: 'serial',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Владелец устройства',
          key: 'device_verification_owner',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Контроль целостности ПО',
          key: 'device_integrity',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Поверка',
          key: 'attestation',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Корпус',
          key: 'case_opened',
          inverse: true,
        },
      ],
      pagination: {
        limit: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      error: (state) => state.devices.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          devices: [],
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getDevices();
    },
  },
  async created() {
    this.getDevices();
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    async getDevices() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = '';
      if (this.sortKey === 'attestation' || this.sortKey === 'device_verification_owner') {
        return false;
      } else if (this.sortKey === 'company_name') {
        order_by = `&order_by=${sortInverse}${this.sortKey}`;
      } else {
        order_by = `&device_order_by=${sortInverse}${this.sortKey}`;
      }
      this.resultFilter = `?page=${this.currentPage - 1}&per_page=10${this.filterString}` + `${order_by}`;
      let res = await devicesApi.getDevices(this.resultFilter);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.devices = res.data.items;
    },
    sortingDevices(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getDevices();
      }
    },
    async submitFilter(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getDevices();
    },
    getCountDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };
      this.$store.dispatch(devicesActionTypes.getCountDevices, data);
    },
    async exportDevices() {
      let filterStringNoSmbl = this.filterString.substring(1);
      if (this.filterString.length) {
        this.resultFilterForLoad =
          `${filterStringNoSmbl}` + `&device_order_by=-${this.sortKey}&activate=true`;
      } else {
        this.resultFilterForLoad = `device_order_by=-${this.sortKey}&activate=true`;
      }
      devicesApi
        .exportNewDevices(this.resultFilterForLoad, {})
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'))
        .catch(function (error) {
          if (error.request.status == '303') {
            this.$toast.error(error);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.profile-devices {
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
