<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Добавление устройств
          p.text-size--small.text-color--blue.mt-3 Для успешного добавления укажите серийный номер и скан документа, подтверждающего покупку. Если все устройства указаны в одном документе, приложите его для каждого устройства

        form(@submit.prevent="" :class="'form'")
          .row.flex-column.align-items-center.g-4
            .col-14.col-md-10(v-if="validationErrors.message")
              app-validation-errors(:validation-errors="validationErrors.message")
              template(v-if="validationErrors.info")
                ul(
                  v-for="(request, i) in validationErrors.info"
                  :key="request.serial"
                  class=['form__info', 'text-size--small', 'mt-2']
                )
                  li
                    span.text-family--italic.me-3
                      template(v-if="request.serial") {{ `#${request.serial}` }}
                      template(v-else) {{ `#${request}` }}
                    span(v-if="request.comment") {{ request.comment }}

            .col-14.col-md-10
             .form__caption.text-center.maw365.pb-2 Владелец устройства
             .maw365
              autocomplete(
                :search="search"
                placeholder="Введите владельца устройства"
                aria-label="Введите владельца устройства"
                :get-result-value="getResultValue"
                @submit="handleSubmit"
                )
            .col-14.col-md-10(v-for="(device, i) in form.devices" :key="i" :set="$response = v$.form.devices.$each.$response")
              .row.g-4
                .col
                 .outer-form
                  label.form__label.form__label--float
                    input(
                      :class="['form__field form__field--no-border', { 'is-error': $response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty }]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="device.value"
                      autocomplete="off"
                      placeholder="&nbsp;"
                    )
                    span.fl Серийный номер
                  ul.form__error(v-if="$response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty")
                   li(v-for="error of $response.$errors[i].value" :key="error.$uid")
                     template(v-if="error.$params.type === 'required'") {{ v.errors.required }}
                     template(v-if="error.$params.type === 'alphaNum'") {{ v.errors.alphaNum }}
                     template(v-if="error.$params.type === 'isUnique'") Серийные номера не должны совпадать
                  .row.gx-4
                   .col-7
                      .text-size--small.mb-2.mt-4 Дата поверки
                      date-picker(
                        prefix-class="app"
                        placeholder="Дата поверки"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        v-model="device.starts"
                        :disabled-date="disableAfterToday"
                      )
                   .col-7
                     .text-size--small.mb-2.mt-4  Окончание срока поверки
                     date-picker(
                        prefix-class="app"
                        placeholder="Окончание срока поверки"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        v-model="device.ends"
                        :disabled-date="disableExpiration"
                      )
                   .col-14
                    .text-size--small.mb-2.mt-4  Номер свидетельства
                    input(
                      :class="['form__field', { 'is-error': $response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty}]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="device.device_attestations"
                      placeholder="Введите номер свидетельства"
                      autocomplete="off"
                    )
                .col-auto
                  button(
                    v-if="i === form.devices.length - 1"
                    type="button"
                    :disabled="form.devices.length >= limit"
                    @click="changeNumOfDevices('add')"
                    class=['form__add-button']
                  )
                    app-svg-icon(name="plus-thin")

                  button(
                    v-else
                    type="button"
                    @click="changeNumOfDevices('delete', i)"
                    class=['form__delete-button']
                  )
                    app-svg-icon(name="minus")
              ul.form__error(v-if="$response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty")
                li(v-for="error of $response.$errors[i].device_attestations" :key="error.$uid")
                  template(v-if="error.$params.type === 'required'") {{ v.errors.required }}
                  template(v-if="error.$params.type === 'alphaNum'") {{ v.errors.alphaNum }}
                  template(v-if="error.$params.type === 'isUnique'") Серийные номера не должны совпадать

            .col-14.col-md-10
              .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                input(
                  class="d-none"
                  type="file"
                  name="document"
                  @change="onFileSelected"
                  @click="v$.$touch()"
                  ref="fileInput"
                  accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                )
                div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                  span.flex-shrink-0.me-3
                    app-svg-icon(name="document")

                  ul.flex-grow-1.overflow-hidden
                    li.form__file-name.text-color--black {{ form.file.name }}
                    li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                  button(
                    type="button"
                    @click="clearFileInput"
                    class=['form__file-delete', 'flex-shrink-0 ms-3']
                  )
                    app-svg-icon(name="trash")

                .text-color--blue.text-size--small(v-else) Приложите скан документа

                button(
                  class=['form__file-button', 'd-flex justify-content-end align-items-center maw365']
                  @click="$refs.fileInput.click()"
                )
                  app-svg-icon(name="clip")

              ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                li {{ v.errors.required }}

            .col-14.col-md-10.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                @click="onSubmit"
                class=['button', 'button--accent', 'form__button','maw365']
              )
                span.button__content Отправить
</template>

<script>
import usersApi from '@/api/users';
import {mapState} from 'vuex';
import devicesApi from '@/api/devices';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf, alphaNum} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import {convertDate} from '@/helpers/utils';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import {actionTypes as filtersActionTypes} from '@/store/modules/filters';
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
export default {
  name: 'AppCreateDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,
    DatePicker,
    Autocomplete,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      document_id: null,
      v: variables,
      form: {
        devices: [
          {
            id: 0,
            value: '',
            device_attestations: '',
            starts: '',
            ends: '',
          },
        ],
        file: null,
        user: true,
        userId: '',
      },
      validationErrors: {
        message: '',
        info: null,
      },
      limit: 100,
      patientsList: [],
    };
  },
  validations() {
    return {
      form: {
        devices: {
          $each: helpers.forEach({
            id: {},
            value: {
              required,
              alphaNum,
              isUnique: function (value) {
                if (value === '') return true;

                let count = 0;
                this.form.devices.forEach((device) => {
                  if (value === device.value) ++count;
                });
                return count < 2;
              },
            },
            device_attestations: {required},
            starts: {required},
            ends: {required},
          }),
        },
        userId: {required},
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  watch: {
    '$store.state.filters.data': {
      //immediate: true,
      handler() {
        const state = this.$store.state?.filters?.data?.users;
        this.patientsList = state?.map((item) => {
          return {
            text: item.text,
            id: item.value,
            role_code: item.role_code,
          };
        });

        this.patientsList = this.patientsList?.reduce((o, i) => {
          if (!o.find((v) => v.id == i.id) && i.role_code === 'USER') {
            o.push(i);
          }
          return o;
        }, []);
      },
    },
  },
  mounted() {
    this.$store.dispatch(filtersActionTypes.getFilters);
    this.$store.dispatch(filtersActionTypes.getFiltersType);
  },
  methods: {
    async search(input) {
      let res = await usersApi.getUsersSuggestionList(input);
      return res.data.items;
    },
    getResultValue(result) {
      this.form.company_name = result.company_name;
      return result.company_name;
    },
    handleSubmit(result) {
      this.form.userId = result.id;
    },
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.form.devices.ends) {
        return date > today;
      } else if (this.form.devices.ends < today) {
        return date > this.form.devices.ends;
      } else {
        return date > today;
      }
    },
    disableExpiration(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        return date < today;
      } else {
        return date < this.form.devices.starts;
      }
    },
    closeModal() {
      this.resetForm();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
      this.$toast.success('Устройство добавлено');
    },
    changeNumOfDevices(slug, index) {
      slug === 'add'
        ? this.form.devices.push({id: index, value: ''})
        : this.form.devices.splice(index, 1);
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    async onSubmit() {
      const formDataImportFile = new FormData();
      formDataImportFile.append('file', this.form.file);
      let result = await devicesApi.getDeviceDociment(formDataImportFile);
      if (!this.v$.$invalid) {
        const createDeviceForm = {
          devices: [],
          document_id: result.data.document_id,
          user_id: this.form.userId,
          case_opened: false,
          device_integrity: true,
          device_verified: true,
        };
        this.form.devices.forEach((el) => {
          createDeviceForm.devices.push({
            serial: el.value,
            device_attestation: el.device_attestations,
            device_attestation_start: convertDate(el.starts),
            device_attestation_end: convertDate(el.ends),
          });
        });
        this.$store
          .dispatch(devicesActionTypes.createDevices, createDeviceForm)
          .then(() => {
            this.closeModal();
            this.success();
          })
          .catch((result) => {
            if (result.message === 'Request failed with status code 409') {
              this.validationErrors.message = 'A device serial must be unique';
            } else this.validationErrors.message = result;
          });
      }
    },
    resetForm() {
      this.v$.$reset();
      this.validationErrors.message = '';
      this.validationErrors.info = null;
      this.form.devices = [{id: 0, value: ''}];
      this.clearFileInput();
    },
    selectedPatients(input) {
      this.form.userId = input.value;
    },
  },
};
</script>
<style lang="scss">
.form__radio-button--high {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.form__radio-button--left {
  border-radius: 6px 0 0 6px !important;
  border-right: none !important;
}
.form__radio-button--right {
  border-radius: 0 6px 6px 0 !important;
}
</style>
