<template lang="pug">
section(
  v-if='data'
  class=['user-card', 'profile-card']
)
  .row.align-items-center.g-4.gy-xl-0
    .col-3.col-md-3
      span(v-if="data.created_at").text-color--blue.text-color-md-blue-dark {{ toLocalDateExam(data.created_at) }} {{ toLocalTimeExam(data.created_at) }}
      span(v-else) &#8212;

    .col-5.col-md-5
      .d-flex.align-items-center.justify-content-end.justify-content-md-start.button-result
        button(
          v-if="data.path"
          class=['profile-card__button--document', 'link--accent', 'text-overflow']
          type="button"
          @click.prevent="getFile(data.path)"
        )
          span(v-if="data.path").text-color--blue.text-color-md-blue-dark.incomingAction {{ data.path }}
          span(v-else) &#8212;

    .col-3.col-md-3.word-wrap.text-wrap
      span(v-if="data.description") {{ data.description }}
      span(v-else) &#8212;

    .d-none.d-md-block.col-3
      span(:class="{'text-color--orange': getDocumentType === 'Произошла ошибка'}") {{ getDocumentType }}


</template>

<script>
import {mapState} from 'vuex';
import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'ReportCard',
  components: {
    AppSvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
    getDocumentType() {
      return this.data.pending_task_type === 'complete'
        ? 'Готов к скачиванию'
        : this.data.pending_task_type === 'pending'
        ? 'В ожидании обработки'
        : this.data.pending_task_type === 'work'
        ? 'Документ обрабатывается'
        : 'Произошла ошибка';
    },
  },
  methods: {
    getFile(link) {
      var frame = document.createElement('iframe');
      frame.src = link;
      frame['download'] = 1;
      document.body.appendChild(frame);
    },
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.incomingAction {
  text-decoration: underline;
  cursor: pointer;
}
.button-result {
  max-width: 90%;
}
</style>
