<template lang="pug">
figure.logo.d-flex.flex-column.align-items-center
  app-loading(v-if="isLoading")
  img(v-if="logoSrc" class="logo__img" :src="logotype")
  img(v-else class="logo__img" src="@/assets/img/logo.svg")
  figcaption(class="logo__caption text-color--blue" v-if="caption") {{ caption }}

</template>

<script>
import {mapState} from 'vuex';
import AppLoading from '../common/Loading';

export default {
  name: 'Logo',
  components: {
    AppLoading,
  },
  props: {
    caption: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      decodedUrl: '',
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.settings.isLoading,
      logoSrc: (state) => state.settings.logoSrc,
      logotype() {
        let spliting = this.logoSrc.data.value.split('.');
        let fileFormat = spliting[spliting.length - 1];
        if (fileFormat === 'svg') {
          fetch(this.logoSrc.data.download_link)
            .then((response) => response.text())
            .then((svg) => {
              let url = 'data:image/svg+xml;base64,' + window.btoa(svg);
              this.decodedUrl = url;
              return this.decodedUrl;
            });
          return this.decodedUrl;
        } else {
          return this.logoSrc.data.download_link;
        }
      },
    }),
  },
};
</script>

<style lang="scss">
.logo {
  &__caption {
    margin-top: $spacer * 2.3;
  }
}
@include mediaToDown($md) {
  .logo {
    &__img {
      max-width: $spacer * 19;
    }
    &__caption {
      margin-top: $spacer;
    }
  }
}
</style>
