<template lang="pug">
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__header
    .row.align-items-center
      .col-14.col-md-auto.order-last.order-md-first
          app-title(:type="'large'" :id="'isAnchorForBtn'") Контроль целостности ПО
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
          .d-flex.justify-content-end
            button(
              type="button"
              @click="addModal"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить КЦ
        .col-12
          NavDevice

  .profile-devices__content.mt-20.position-relative
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      div(v-if="devices")
        template(v-if="devices && devices.length")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    v-if="column.caption || column.icon"
                    type="button"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                    @click="sortingDevices(column.key, column.inverse)"
                  )
                    span.d-flex.align-items-center
                      span {{ column.caption }}
                      span.d-flex.ms-3
                        app-svg-icon(name="down-arrow")

            ul.profile-table__body
              li.profile-table__row(v-for="device in devices" :key="devices.id")
                section.profile-card
                  .row.align-items-md-center.gy-4.gy-md-0
                    .col-3
                      .text-color--blue.mb-2.d-xl-none Версия прошивки
                      span(v-if="device.version") {{ device.version }}
                      span(v-else) &#8212;
                    .col-3
                      .text-color--blue.mb-2.d-xl-none Контрольная сумма
                      span(v-if="device.sum") {{ device.sum }}
                      span(v-else) &#8212;
                    .col-3
                      .text-color--blue.mb-2.d-xl-none Автор записи
                      span(v-if="device.email") {{ device.email }}
                      span(v-else) &#8212;
                    .col-3
                      .text-color--blue.mb-2.d-xl-none Дата записи
                      span(v-if="device.creation_time_formatted") {{ device.creation_time_formatted }}
                      span(v-else) &#8212;
                    .col-md-2.d-flex.justify-content-end
                      button(
                        type="button"
                        @click.prevent="editModal(device)"
                        class=['profile-card__button profile-card__button--delete no-border', 'd-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                        app-svg-icon(name="edit")
                      button(
                        type="button"
                        @click.prevent="deleteModal(device.id)"
                        class=['profile-card__button profile-card__button--delete no-border', 'd-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                        app-svg-icon(name="trash")

          .d-flex.justify-content-center.mt-5.d-none
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              app-svg-icon(name="wrong")
            span Нет устройств

  // delete modal
  app-delete-firmware-modal(
    :deleteFirmvareId="deleteFirmvareId"
    :is-visible="isModalVisible.deleteDevice"
    @close="closeModal('deleteDevice')"
    @success="closeModal('deleteDevice')"
  )
  // Edit modal
  app-edit-firmware-modal(
    :data="editDate"
    :is-visible="isModalVisible.editFirmware"
    @close="closeModal('editFirmware')"
    @success="closeModal('editFirmware')"
  )
   // Add modal
  app-add-firmware-modal(
    :data="deleteDevice"
    :is-visible="isModalVisible.addFirmware"
    @close="closeModal('addFirmware')"
    @success="closeModal('editFirmware')"
  )

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import is from 'is_js';
import variables from '@/helpers/variables';
import {declOfNum, blobToFile, toLocalDate} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';

import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import {modalsMixin} from '@/mixins/modals';
import {filterMixin} from '@/mixins/filter';

import NavDevice from '@/components/profile/common/NavDevice';
import AppDeleteFirmwareModal from '@/components/profile/admin/devices/AppDeleteFirmwareModal';
import AppEditFirmwareModal from '@/components/profile/admin/devices/AppEditFirmwareModal';
import AppAddFirmwareModal from '@/components/profile/admin/devices/AppAddFirmwareModal';
export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    AppTitle,
    AppSvgIcon,
    AppPagination,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    NavDevice,
    AppDeleteFirmwareModal,
    AppEditFirmwareModal,
    AppAddFirmwareModal,
  },
  mixins: [modalsMixin, filterMixin],
  data() {
    return {
      v: variables,
      declOfNum,
      isModalVisible: {
        createDevice: false,
        deleteDevice: false,
        successDeleted: false,
        successCreated: false,
        editFirmware: false,
        addFirmware: false,
      },
      deleteDevice: {},
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'версии прошивки',
          value: 'creation_date',
          selected: true,
          inverse: true,
        },
        {
          caption: 'серийному номеру',
          value: 'serial',
          selected: false,
          inverse: false,
        },
        {
          caption: 'владельцу устройства',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'количеству осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-3',
          caption: 'Версия прошивки',
          key: 'serial',
          inverse: false,
        },
        {
          class: 'col-3',
          caption: 'Контрольная сумма',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Автор записи',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-3',
          caption: 'Дата записи',
          key: 'exam_count',
          inverse: true,
        },
      ],
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
      editDate: {},
      deleteFirmvareId: null,
    };
  },
  async mounted() {
    await this.$store.dispatch('actionGetFirmware');
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      devices: (state) => state.firmware.firmware,
      devicesCount: (state) => state.devices.count,
      error: (state) => state.devices.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          devices: [],
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getDevices(this.filters, this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getDevices(this.filters, this.pagination.limit, this.offset);
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getDevices(filters, limit, offset) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.filterDevices, data).then((response) => {
        if (this.isTouchDevice) {
          this.closeFilter('submit');
          this.$refs.filter.setBtnPosition();
        }
        this.pagination.total = response.total;
        this.isFiltered = Object.keys(filters).length > 0;
        this.reverseSorting();
      });
    },
    getCountDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.getCountDevices, data);
    },
    exportDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store
        .dispatch(devicesActionTypes.exportDevices, data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingDevices(value, inverse);
    },
    sortingDevices(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getDevices(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    deleteModal(data) {
      this.deleteFirmvareId = data;
      this.isModalVisible.deleteDevice = true;
    },
    editModal(data) {
      this.editDate = data;
      this.isModalVisible.editFirmware = true;
    },
    addModal() {
      this.isModalVisible.addFirmware = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .profile-card {
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    padding: 0;
  }
  &-table {
    &__row {
      position: relative;
      padding: 0rem 1.5625rem 0rem 1rem;
      background-color: #ffffff;
      font-size: 0.75rem;
    }
  }
}
</style>
