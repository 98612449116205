<template lang="pug">
div
  .patient-exams__header.text-size--normal.mb-12.d-flex.flex-xl-row.flex-column
    span(
      @click="showComments"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Комментарии к этому пользователю

  .comments(v-if="showBlock")
    label.submit-form-comment
      textarea(
        id="editor"
        :class="['form__field w-100 doctor-exam-detail-modal__text']"
        placeholder="Добавить комментарий"
        v-model="currentComment"
      )
      button(
        type="button"
        @click="createComment()"
        class=['send-comment']
      )
        span.send-comment-content >
    .comments-section
      div(v-for="comment in comments" :key="comment.id")
        p.comment-header.text-color--blue-dark {{ comment.user.fio_name }} {{ toLocalDateExam(comment.created_at) }} {{ toLocalTimeExam(comment.created_at) }}
        p.text-color--blue-dark {{ comment.comment }}

  </template>

<script>
import patientApi from '@/api/patients';
import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';

export default {
  name: 'AppPatientComments',
  props: {
    patientCode: String,
  },
  data() {
    return {
      currentComment: '',
      comments: [],
      showBlock: false,
    };
  },
  async mounted() {},
  methods: {
    async createComment() {
      await patientApi.createPatientComment({
        patient_code: this.patientCode,
        comment: this.currentComment,
      });
      this.currentComment = '';
      await this.getComments();
    },
    async getComments() {
      let comments = await patientApi.getPatientComment(this.patientCode);
      this.comments = comments.data.items;
    },
    async showComments(item) {
      this.showBlock = !this.showBlock;
      await this.getComments(item);
    },
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.comments {
  width: 60%;
  margin-top: 30px;
}

.comment-header {
  font-weight: 600;
}

#editor {
  color: #515f8f;
}
#editor::placeholder {
  color: #515f8f;
}
</style>
