<template lang="pug">
header(:class="['header', 'd-flex align-items-center', {'is-profile d-none d-xl-flex': isLoggedIn}]")
  .container-fluid
    transition(name="fade" mode="out-in")
      template(v-if="isLoggedIn")
        .row.align-items-center
          .col-3
          .col
            .d-flex.justify-content-center
              router-link(:to="{name: 'dashboard'}")
                app-logo
          .col-3
            .d-flex.justify-content-end
              router-link(
                :to="{name: 'settings'}"
                class=['text-decoration--underline text-size--small link--accent', 'me-5']
              ) Настройки личного кабинета

              button(
                @click="onLogout"
                type="button"
                class=['text-decoration--underline text-size--small link--accent']
              ) Выход

      template(v-else)
        .row.justify-content-center
          .col-14.col-xl-6.col-xxl-5
            nav
              ul.d-flex.flex-column.flex-xl-row.justify-content-between.align-items-center
                template(v-if="$route.name === 'register'")
                  //- li.mb-2.mb-xl-0
                    router-link(
                      :to="{name: 'registerStatus'}"
                      class=['text-decoration--underline text-size--small']
                    ) Проверить статус моей заявки
                  li
                    router-link(
                      :to="{name: 'login'}"
                      class=['text-decoration--underline text-size--small']
                    ) Войти в личный кабинет

                template(v-if="$route.name === 'login'")
                  //- li.mb-2.mb-xl-0
                    router-link(
                      :to="{name: 'registerStatus'}"
                      class=['text-decoration--underline text-size--small']
                    ) Проверить статус моей заявки
                  //- li
                    router-link(
                      :to="{name: 'register'}"
                      class=['text-decoration--underline text-size--small']
                    ) Регистрация нового клиента


                template(v-if="$route.name === 'registerStatus'")
                  li.mb-2.mb-xl-0
                    router-link(
                      :to="{name: 'login'}"
                      class=['text-decoration--underline text-size--small']
                    ) Войти в личный кабинет
                  //- li
                    router-link(
                      :to="{name: 'register'}"
                      class=['text-decoration--underline text-size--small']
                    ) Регистрация нового клиента
          app-tooltip(:content="'Веб-портал для просмотра и анализа данных, поступающих от устройств для проведения телемедицинских осмотров'" :classes="'text-color--accent text-decoration--underline text-size--small mt-5 pt-2'")
            template(v-slot:body)
              span.ms-3 Куда я попал или что за портал?
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes, gettersTypes} from '@/store/modules/auth';
import AppTooltip from '@/components/common/Tooltip';
import AppLogo from '@/components/common/Logo';

export default {
  name: 'AppHeader',
  components: {
    AppLogo,
    AppTooltip,
  },
  computed: {
    ...mapGetters({
      currentUser: gettersTypes.currentUser,
      isLoggedIn: gettersTypes.isLoggedIn,
      isAnonymous: gettersTypes.isAnonymous,
    }),
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
    }),
  },
  methods: {
    onLogout() {
      this.$store.dispatch(actionTypes.logout).then(() => {
        this.$router.push({name: 'home'});
      });
    },
  },
};
</script>

<style lang="scss">
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: get($index, sm);
  height: $header-height;

  &.is-profile {
    position: fixed;
    border-bottom: $border-width solid get($colors, gray);
    overflow: hidden;

    .logo__img {
      max-width: $spacer * 15;
    }
  }
  @include mediaToDown($xl) {
    position: relative;
  }
  @include mediaToDown($md) {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    height: auto;
  }
}
</style>
