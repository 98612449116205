<template lang="pug">
simplebar.auth
  .container-fluid
    .row.flex-column.align-items-center
      .col-14.col-xl-6.col-xxl-5
        router-link(:to="{name: 'home'}")
          app-logo
        .auth__container
          transition(name="fade" mode="out-in")
            router-view
</template>

<script>
import simplebar from 'simplebar-vue';
import AppLogo from '@/components/common/Logo';

export default {
  name: 'AppAuth',
  components: {
    AppLogo,
    simplebar,
  },
};
</script>

<style lang="scss">
.auth {
  padding-top: $spacer * 7;
  padding-bottom: $spacer * 7;

  &__container {
    min-height: $spacer * 32;
    position: relative;
    margin-top: $spacer * 7;
    padding: $spacer * 4.2;
    background-color: get($colors, white);
    box-shadow: get($shadows, large);
    border-radius: $border-radius-lg;
  }
  &__status {
    padding: $spacer * 1.35 0;
    border-radius: $border-radius-sm;
    border: $border-width solid get($colors, gray);

    svg {
      width: $spacer * 2;
      height: $spacer * 2;
    }
    .icon {
      &__wrong {
        fill: get($colors, danger);
      }
      &__allright {
        fill: get($colors, success);
      }
    }
  }
  &__back-button {
    position: absolute;
    left: $spacer * 3;
    top: $spacer * 4.8;
    color: get($colors, blue-dark);
    @include transition(color);

    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);
      }
    }
  }
}
@include mediaToDown($md) {
  .auth {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 10;

    &__container {
      margin-top: $spacer * 3;
      padding: $spacer * 2.5 $spacer;
    }
    &__back-button {
      position: static;
      padding-bottom: $spacer;
      padding-left: $spacer;
    }
  }
}
</style>
