<template lang="pug">
.inspection-card__request-card.col-13
  .row.gx-1
    .row.mb-4
      .col-6.col-sl-8.col-xl-8
        .row.align-items-center
          .col.col-sl-4.px-2
            .title.title--small.pointer
              span Заключение:
          .col.admission.text-center
            span.text-color--success.text-uppercase(v-if="exam.admission_to_work === true") Допущен
            span.text-color--danger.text-uppercase(v-else) Не допущен
          .col
            span.text-uppercase {{ toLocalDateExam(exam.admission_datetime) }}&nbsp;
            span.text-uppercase {{ toLocalTimeExam(exam.admission_datetime) }}
        .row.mt-4.align-items-center
          .col-4
            .row
              .col.px-2
                .title.title--small.pointer
                  span Медработник:
          .col
            span.text-color--blue-dark {{ exam.doctor_full_name }}
      .col-7.col-sl-6.col-xl-6.d-flex
        .col.px-2
          .title.title--small.pointer
            span(v-if='exam.reasons_array !== null') Причина недопуска:
        .col-10.col-sl-8.col-xl-6
          span
            span(v-if="exam.exam_comment && exam.exam_comment !== 'undefined'") {{ exam.exam_comment }}
            ul.list-inline
              li.list-inline-item(v-for="reason in exam.reasons_array") {{`${reason}. `}}

</template>

<script>
import AppAvatar from '@/components/profile/common/Avatar';
import AppModal from '@/components/common/Modal';
import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';

export default {
  name: 'DoctorExamCardConclusion',
  components: {
    AppAvatar,
    AppModal,
  },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.inspection-card__request-card {
  .admission {
    font-weight: 600;
    padding: 0;
  }
  .title span {
    font-weight: 600;
  }
  background: #fff;
  padding: 0 15px 20px;
}
.inspection-card__request-card {
  margin-left: auto;
  padding: 10px 15px;
  border-radius: $border-radius-sm;
  border: 1px solid #dfe4f8;
  background-color: get($colors, white);
  margin-bottom: 15px;
  .span {
    font-weight: 400;
    line-height: 18ox;
  }
  ul {
    padding: 0;
  }
  ul li {
    display: inline;
    margin: 0;
    padding: 0;
  }

  @include mediaToDown($xl) {
    padding: $spacer * 1.2;
    box-shadow: get($shadows, default);
  }
}
</style>
