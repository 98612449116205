<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(v-if="isVisible" @close="close")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Редактирование медработника<br>{{ doctor.full_name }}

          .px-md-4
            form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
              app-validation-errors(
                v-if="localValidationErrors"
                :validation-errors="localValidationErrors"
                :classes="'mb-3'"
              )
              .row.g-4
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }, {'is-changed': form.isChanged.firstName}]"
                      type="text"
                      v-model="v$.form.firstName.$model"
                      @keyup="changeField('firstName')"
                      placeholder="Имя"
                    )
                  ul.form__error(v-if="v$.form.firstName.$errors.length")
                    li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }, {'is-changed': form.isChanged.lastName}]"
                      type="text"
                      v-model="v$.form.lastName.$model"
                      @keyup="changeField('lastName')"
                      placeholder="Фамилия"
                    )
                  ul.form__error(v-if="v$.form.lastName.$errors.length")
                    li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', {'is-changed': form.isChanged.middleName}]"
                      type="text"
                      v-model="form.middleName"
                      @keyup="changeField('middleName')"
                      placeholder="Отчество"
                    )

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.email.$errors.length }, {'is-changed': form.isChanged.email}]"
                      type="email"
                      v-model="v$.form.email.$model"
                      @keyup="changeField('email')"
                      placeholder="E-mail"
                    )
                  ul.form__error(v-if="v$.form.email.$errors.length")
                    li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.password.$errors.length }, {'is-changed': form.isChanged.password}]"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="v$.form.password.$model"
                      @keyup="changeField('password')"
                      placeholder="Новый пароль"
                    )
                    span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                      app-svg-icon(v-if="showPassword" :name="'eye-open'")
                      app-svg-icon(v-else :name="'eye-close'")

                  ul.form__error(v-if="v$.form.password.$errors.length")
                    li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                      template(v-if="v$.form.password.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="v$.form.confirmPassword.$model"
                      placeholder="Повторите пароль"
                    )
                    span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                      app-svg-icon(v-if="showPassword" :name="'eye-open'")
                      app-svg-icon(v-else :name="'eye-close'")

                  ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                    li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="(v$.form.confirmPassword.$model) && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

                .col-14.col-md-14
                  .text-size--small.mb-3 Место работы
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.placeOfWork.$errors.length }, {'is-changed': form.isChanged.placeOfWork}]"
                      type="text"
                      v-model="v$.form.placeOfWork.$model"
                      @keyup="changeField('placeOfWork')"
                      placeholder="Название организации"
                    )
                  ul.form__error(v-if="v$.form.placeOfWork.$errors.length")
                    li(v-for="error of v$.form.placeOfWork.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                .col-14.col-md-7
                  .text-size--small.mb-3 ИНН медицинской организации
                  label.form__label
                    input(
                      v-model="v$.form.med_organisation_inn.$model"
                      :class="['form__field',{'is-changed': form.isChanged.med_organisation_inn}]"
                      type="text"
                      placeholder="ИНН"
                       @keyup="changeField('med_organisation_inn')"
                      v-mask="'##########'"
                    )
                .col-14.col-md-7
                  .text-size--small.mb-3 Документ о медицинском образовании
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.seriesNumber.$errors.length }, {'is-changed': form.isChanged.seriesNumber}]"
                      type="text"
                      v-model="v$.form.seriesNumber.$model"
                      @keyup="changeField('seriesNumber')"
                      placeholder="Серия и номер"
                    )
                  ul.form__error(v-if="v$.form.seriesNumber.$errors.length")
                    li(v-for="error of v$.form.seriesNumber.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="v$.form.seriesNumber.$model && error.$validator === 'valid'") {{ v.errors.invalid }}
                .col-14.col-md-7
                  .text-size--small.mb-3 Номер лицензии организации
                  label.form__label
                    input(
                      :class="['form__field', {'is-changed': form.isChanged.licence_number}]"
                      type="text"
                        v-model="v$.form.licence_number.$model"
                      @keyup="changeField('licence_number')"

                      placeholder="Номер лицензии"
                    )
                .col-14.col-md-7
                 .row.gx-3
                  .col-14
                   .text-size--small.mb-3 Дата выдачи и срок окончания лицензии
                  .col-7
                    label(:class="['form__label',{'is-changed': form.isChanged.licence_data_reciving}]")
                      date-picker(
                       v-model="v$.form.licence_data_reciving.$model"
                        prefix-class="app"
                        placeholder="Дата"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableAfterToday"
                        :default-value="new Date()"

                        @change="changeField('licence_data_reciving')"
                      )
                  .col-7
                     label(:class="['form__label',{'is-changed': form.isChanged.licence_data_ending}]")
                      date-picker(
                    v-model="v$.form.licence_data_ending.$model"
                        prefix-class="app"
                        placeholder="Дата"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableExpiration"
                        @change="changeField('licence_data_ending')"
                      )
                  //- ul.form__error(v-if="v$.form.seriesNumber.$errors.length")
                  //-   li(v-for="error of v$.form.seriesNumber.$errors" :key="error.$uid")
                  //-     template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  //-     template(v-if="v$.form.seriesNumber.$model && error.$validator === 'valid'") {{ v.errors.invalid }}

                .col-14
                  .text-size--small.mb-3 Сертификат о подготовке медработника
                  .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                    input(
                      class="d-none"
                      type="file"
                      name="document"
                      @change="onFileSelected"
                      @click="v$.$touch()"
                      ref="fileInput"
                      accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                    )

                    div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                      span.flex-shrink-0.me-3
                        app-svg-icon(name="document")

                      ul.flex-grow-1.overflow-hidden
                        li.form__file-name.text-color--black {{ form.file.name ? form.file.name : 'Документ' }}
                        li.mt-1(v-if="form.file.size") {{ Number(form.file.size/1000000).toFixed(2) }} mb

                      button(
                        type="button"
                        @click="clearFileInput"
                        class=['form__file-delete', 'flex-shrink-0 ms-3']
                      )
                        app-svg-icon(name="trash")

                    .text-color--blue.text-size--small(v-else) Приложите скан документа

                    button(
                      class=['form__file-button', 'd-flex justify-content-end align-items-center']
                      @click="$refs.fileInput.click()"
                    )
                      app-svg-icon(name="clip")

                  ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                    li(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .row.g-5.mt-0
                .col-14
                  .mb-4
                    app-title Назначить клиентов
                    p.text-size--small.text-color--blue.mt-2 Назначьте клиентов для проверки их пользователей медработником

                  app-multiple-select(
                    v-if="filters"
                    :initial-values="initialValues.users"
                    ref="multipeSelectUsers"
                    :results-view="'detail'"
                    :search-type="'users'"
                    :placeholder="'Введите название организации или ФИО ИП'"
                    @update="updateSelectedUsers"
                  )
                  ul.form__error(v-if="v$.form.users.$invalid")
                    li {{ v.errors.required }}

                .col-14
                  button(
                    type="submit"
                    @click="onSubmit"
                    :disabled="v$.$invalid || !isChanged"
                    class=['button', 'button--accent', 'form__button']
                  )
                    span.button__content Сохранить
    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Данные успешно изменены

          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeSuccessModal"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content Назад
</template>

<script>
import doctorsApi from '@/api/doctors';
import {mapState} from 'vuex';
import {actionTypes as filtersActionTypes} from '@/store/modules/filters';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';

import {isEmptyObj, arrayDifference} from '@/helpers/utils';
import is from 'is_js';
import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required, requiredIf, minLength, sameAs, email} from '@vuelidate/validators';

import AppLoading from '@/components/common/Loading';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppMultipleSelect from '@/components/common/MultipleSelect';

import {modalsMixin} from '@/mixins/modals';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import {convertDate} from '@/helpers/utils';

import {mask} from 'vue-the-mask';
export default {
  name: 'AppChangeDoctorModal',
  components: {
    AppLoading,
    AppValidationErrors,
    AppTitle,
    AppModal,
    AppSvgIcon,
    AppMultipleSelect,
    DatePicker,
  },
  directives: {
    mask,
  },
  mixins: [modalsMixin],
  props: {
    doctor: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      doctorsUsers: [],
      v: variables,
      form: {
        isChanged: {
          firstName: false,
          lastName: false,
          middleName: false,
          email: false,
          password: false,
          placeOfWork: false,
          seriesNumber: false,
          file: false,
          users: false,

          med_organisation_inn: false,
          licence_number: false,
          licence_data_reciving: '',
          licence_data_ending: '',
        },
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        password: '',
        confirmPassword: '',
        placeOfWork: '',
        seriesNumber: '',
        file: null,
        users: [],
        med_organisation_inn: '',
        licence_number: '',
        licence_data_reciving: '',
        licence_data_ending: '',
      },
      showPassword: false,
      localValidationErrors: null,
    };
  },

  computed: {
    ...mapState({
      isSubmitting: (state) => state.doctors.isSubmitting,
      validationErrors: (state) => state.doctors.validationErrors,
      filters: (state) => state.filters.data,
      globalSettings: (state) => state.settings.data,
    }),

    isChanged() {
      return Object.values(this.form.isChanged).some((item) => item === true);
    },
    initialValues() {
      if (isEmptyObj(this.doctor)) return false;
      return {
        firstName: this.doctor.first_name,
        lastName: this.doctor.last_name,
        middleName: this.doctor.middle_name,
        email: this.doctor.email,
        placeOfWork: this.doctor.place_of_work,
        seriesNumber: this.doctor.series_number,
        file: this.doctor.certificate,
        users: this.doctorsUsers,
        password: null,
        med_organisation_inn: this.doctor.med_organisation_inn,
        licence_number: this.doctor.licence_number,
        licence_data_reciving: new Date(this.doctor.licence_data_reciving),
        licence_data_ending: new Date(this.doctor.licence_data_ending),
      };
    },
  },
  watch: {
    isVisible: function () {
      if (this.isVisible === true) {
        this.getDoctorsUsers();
      }
      return '';
    },
  },
  validations() {
    if (is.not.empty(this.form.password)) {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            valid: function (value) {
              return this.v.regexes.password.test(value);
            },
            containsUppercase: function (value) {
              return /[A-Z]/.test(value);
            },
            containsLowercase: function (value) {
              return /[a-z]/.test(value);
            },
            containsNumber: function (value) {
              return /[0-9]/.test(value);
            },
            minLength: minLength(6),
          },
          confirmPassword: {
            required,
            sameAsPassword: sameAs(this.form.password),
          },
          placeOfWork: {
            required,
          },
          seriesNumber: {
            required,
            valid: function (value) {
              return this.v.regexes.alphaNumeric.test(value);
            },
          },
          file: {
            required: requiredIf(() => Boolean(this.form.file)),
          },
          users: {
            required,
          },
          med_organisation_inn: {
            required,
            minLength: minLength(10),
          },
          licence_number: {
            required,
          },
          licence_data_reciving: {
            required,
          },
          licence_data_ending: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {},
          confirmPassword: {},
          placeOfWork: {
            required,
          },
          seriesNumber: {
            required,
            valid: function (value) {
              return this.v.regexes.alphaNumeric.test(value);
            },
          },
          file: {
            required: requiredIf(() => Boolean(this.form.file)),
          },
          users: {
            required,
          },
          med_organisation_inn: {
            required,
            minLength: minLength(10),
          },
          licence_number: {
            required,
          },
          licence_data_reciving: {
            required,
          },
          licence_data_ending: {
            required,
          },
        },
      };
    }
  },
  created() {
    if (!this.filters) this.getSearchData();
    this.setInitialValues();
  },
  updated() {
    this.setInitialValues();
  },
  methods: {
    async getDoctorsUsers() {
      let res = await doctorsApi.getDoctorUsersNoFilter(this.doctor.id);
      this.doctorsUsers = res.data.items;
      let items = []
      for (let i = 0; i < this.doctorsUsers.length; i++) {
        this.doctorsUsers[i]['text'] = this.doctorsUsers[i]['full_name'];
        this.doctorsUsers[i]['value'] = this.doctorsUsers[i]['user_id'];
        items.push(this.doctorsUsers[i]['user_id'])
      }
      this.doctorsUsers = items;
    },
    getSearchData() {
      this.$store.dispatch(filtersActionTypes.getFilters);
    },
    setInitialValues() {
      if (!isEmptyObj(this.doctor)) {
        for (let key in this.initialValues) {
          if (Object.prototype.hasOwnProperty.call(this.initialValues, key)) {
            this.form[key] = this.initialValues[key] || '';
          }
        }
      }
    },
    changeField(key) {
      if (new Date(this.form.licence_data_ending) < new Date(this.form.licence_data_reciving)) {
        // this.form.licence_data_ending = ''
      }
      this.form.isChanged[key] = is.array(this.initialValues[key])
        ? Boolean(arrayDifference(this.form[key], this.initialValues[key]).length)
        : this.form[key] !== (this.initialValues[key] || '');
    },
    updateSelectedUsers(data) {
      this.form.users = data.map((el) => el.value);
      this.changeField('users');
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
      this.changeField('file');
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();
        formData.append('doctor_id', this.doctor.id);
        formData.append('email', this.form.email);
        formData.append('first_name', this.form.firstName);
        formData.append('middle_name', this.form.middleName);
        formData.append('last_name', this.form.lastName);
        formData.append('place_of_work', this.form.placeOfWork);
        formData.append('series_number', this.form.seriesNumber);
        formData.append('clients', this.form.users);

        formData.append('med_organisation_inn', this.form.med_organisation_inn);
        formData.append('licence_number', this.form.licence_number);
        formData.append('licence_data_reciving', convertDate(this.form.licence_data_reciving));
        formData.append('licence_data_ending', convertDate(this.form.licence_data_ending));

        if (this.form.password) formData.append('password', this.form.password);

        if (this.form.file instanceof File)
          formData.append('certificate', this.form.file, this.form.file.name);

        this.$store
          .dispatch(doctorsActionTypes.changeDoctor, formData)
          .then(() => {
            this.close();
            this.$toast.success('Изменения сохранены');
            //this.openModal('success');
          })
          .catch(() => (this.localValidationErrors = this.validationErrors));
        this.$router.go(this.$router.currentRoute);
      }
    },
    resetForm() {
      this.v$.$reset();

      for (let key in this.form.isChanged) {
        if (Object.prototype.hasOwnProperty.call(this.form.isChanged, key)) {
          this.form.isChanged[key] = false;
        }
      }

      this.form.users = [];
      if (this.$refs.multipeSelectUsers) this.$refs.multipeSelectUsers.clear();

      this.isPasswordFieldsVisible = false;
      this.localValidationErrors = null;
      this.clearFileInput();

      this.form.oldPassword = '';
      this.form.password = '';
      this.form.confirmPassword = '';
    },
    close() {
      this.resetForm();
      this.$emit('close');
    },
    closeSuccessModal() {
      this.closeModal('success');
      this.$emit('success');
    },

    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.v$.form.licence_data_ending.$model) {
        return date > today;
      } else if (this.v$.form.licence_data_ending.$model < today) {
        return date > this.v$.form.licence_data_ending.$model;
      } else {
        return date > today;
      }
    },
    disableExpiration(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        return date < today;
      } else {
        return date < this.v$.form.licence_data_reciving.$model;
      }
    },
  },
};
</script>
