<template lang="pug">
.inspection-card
  doctor-exam-card-header(
    :exam="exam"
  )
  .inspection-card__media-container
    .row
      .col-14
          .row.gx-3
            .col-11
              .row.gx-3.mb-4
                .col-6
                  doctor-exam-card-avatar(
                    :avatarSource="archiveAvatar"
                  )
                .col-8
                  doctor-exam-card-video(
                    :src="exam.exam_video"
                  )
              doctor-exam-card-conclusion(
                :exam="exam"
              )
              doctor-exam-card-inspection-result(
                :exam="exam"
              )
              doctor-exam-card-med-docs-history(
                :exam="exam"
              )
              doctor-exam-card-med-docs(
                :exam="exam"
              )
            .col-3
              doctor-exam-card-signatures(
                :exam="exam"
              )
              exam-detail-comments(
                :patientCode="currentPatientId"
              )
              app-gallery(
                :is-visible="isModalVisible.gallery"
                :options="{'sources': [{'src': exam.patient_avatar}]}"
                @close="closeGallery"
                @openEditModal="openModal"
                @openDeleteModal="openModal"
              )


</template>

<script scoped>
import {mapState} from 'vuex';

import ExamDetailComments from '@/components/profile/admin/patients/ExamDetailComments';
import DoctorExamCardInspectionResult from '@/components/profile/doctor/exams/DoctorExamCardInspectionResult';
import DoctorExamCardMedDocsHistory from '@/components/profile/doctor/exams/DoctorExamCardMedDocsHistory';
import DoctorExamCardMedDocs from '@/components/profile/doctor/exams/DoctorExamCardMedDocs';
import DoctorExamCardAvatar from '@/components/profile/doctor/exams/DoctorExamCardAvatar';
import DoctorExamCardVideo from '@/components/profile/doctor/exams/DoctorExamCardVideo';
import DoctorExamCardHeader from '@/components/profile/doctor/exams/DoctorExamCardHeader';
import DoctorExamCardConclusion from '@/components/profile/doctor/exams/DoctorExamCardConclusion';
import DoctorExamCardSignatures from '@/components/profile/doctor/exams/DoctorExamCardSignatures';
import {videoPlayer} from 'vue-video-player';

import {toLocalDate} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppSignature from '@/components/profile/common/Signature';
import patientApi from '@/api/patients';
import examsApi from '@/api/exams';

import AppAvatar from '@/components/profile/common/Avatar';
import AppGallery from '@/components/common/Gallery';
import {galleryMixin} from '@/mixins/gallery';
import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'DoctorExamCardReviewed',
  components: {
    videoPlayer,
    ExamDetailComments,
    AppSvgIcon,
    AppSignature,
    toLocalDate,
    DoctorExamCardInspectionResult,
    DoctorExamCardMedDocsHistory,
    DoctorExamCardMedDocs,
    DoctorExamCardAvatar,
    DoctorExamCardVideo,
    DoctorExamCardHeader,
    DoctorExamCardConclusion,
    DoctorExamCardSignatures,
    AppAvatar,
    AppGallery,
  },
  mixins: [galleryMixin, modalsMixin],
  data() {
    return {
      exam: {},
      patient: {},
      currentExamId: null,
      currentPatientId: null,
      isModalVisible: {
        gallery: false,
      },
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
    archiveAvatar() {
      return {
        source: this.exam.archive_patient_avatar
          ? this.exam.archive_patient_avatar
          : this.exam.patient_avatar
          ? this.exam.patient_avatar
          : null,
        archived: this.exam.archive_patient_avatar ? true : false,
      };
    },
  },
  async created() {
    this.getExamDetail();
  },
  methods: {
    async getExamDetail() {
      let res = await examsApi.getExamDetail(this.$route.params.id);
      this.exam = res.data;
      this.currentPatientId = this.exam.patient_code;
    },
    openGallery(data) {
      this.isModalVisible.gallery = true;
      this.$emit('openGallery', data);
    },
    closeGallery(data) {
      this.isModalVisible.gallery = false;
      this.$emit('closeGallery', data);
    },
    async createComment() {
      await patientApi.createPatientComment({
        patient_code: this.data.patient_code,
        comment: this.currentComment,
      });
      this.currentComment = '';
      let comments = await patientApi.getPatientComment(this.data.patient_code);
      comments = await comments.json();
      this.comments = comments.items;
    },
  },
};
</script>

<style lang="scss">
.inspection-card {
  .inspection-card__media-container {
    .col-13 {
      flex: 0 0 auto;
      width: 100%;
    }
    background: #fff;
    padding: 20px 15px 20px;
  }
}
</style>
