<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Удалить группу?

      .row.flex-column.align-items-center.g-4
        .col-14.col-md-8
          .row.align-items-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deletePrintingGroup"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
  </template>

<script>
import printGroupApi from '@/api/printGroup';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppDeletePrintingGroupModal',
  components: {
    AppModal,
    AppTitle,
    AppSvgIcon,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      required: false,
      default: null,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    async deletePrintingGroup() {
      try {
        await printGroupApi.deletePrintingGroup(this.groupId);
        this.$toast.success('Группа успешно удалена');
        this.$emit('updateData');
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__field {
    color: get($colors, blue-dark);
  }
}
</style>
