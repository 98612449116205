<template lang="pug">
section(class=['patient-card', 'profile-card'] :class="classes")
  .row.align-items-center.g-4.gy-xl-0.justify-content-between
    .col-auto
        span.idDoc {{data.exams_data_id}}

    .col-2.d-xl-block.col-2
      span(v-if="data.created_at") {{toLocalDateExam(data.created_at)}}
      span(v-else) &#8212;
      span(v-if="data.created_at")  {{ toLocalTimeExam(data.created_at) }}

    .col-auto.col-2
      span {{data.company_name}}

    .col-14.col-md-2.col-xl-2
      span.text-wrap {{data.patient_full_name}}

    .col-7.col-md-1.col-xl-1
      span.text-overflow {{$moment(data.date_birth).format('DD.MM.YYYY')}}

    .col-7.col-md-3.col-xl-3
      .d-flex.align-items-center
        .d-flex.align-items-center
          span {{data.pre_diagnosis}}

    .col-7.col-md-2.col-xl-2.d-flex
      span.text-overflow(v-if="userRole === 'admin' && data.doctor_full_name" type="button")
        router-link(
          :to="{name: 'doctor-detail', params: {id: data.doctor_id}}"
          class="text-decoration--underline link--accent"
        ) {{ data.doctor_full_name }}
    .col-7.col-md-auto.col-xl-auto.d-flex
      span.doctorSign(type="button" @click.prevent="openCertificate(data)")

    .col-6.col-md-auto.col-xl-auto.d-flex
      span(type="button" @click.prevent="").pointer(@click='download')
        img(src="@/assets/img/notice.svg")

</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
export default {
  name: 'AppAdminNoticeOutgoing',
  components: {
    AppAvatar,
    AppSvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  methods: {
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
    download() {
      let urls = [];
      if (this.data.med_file || this.data.protocol_file) {
        urls.push(this.data.med_file, this.data.protocol_file);
      }

      for (let i = 0; i < urls.length; i++) {
        if (urls[i]) {
          var frame = document.createElement('iframe');
          frame.src = urls[i];
          frame['download'] = 1;
          document.body.appendChild(frame);
        }
      }
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
  },
};
</script>

<style lang="scss">
.idDoc {
  min-width: 30px;
  display: block;
}
.patient-card {
  &.profile-card {
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;

    .icon {
      &__breathalyzer {
        width: $spacer * 1.8;
        height: $spacer * 1.8;
      }
    }

    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
    }
  }
}
.pointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
</style>
