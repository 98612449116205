import axios from '@/api/axios';

const getAllDevices = (credentials) =>
  axios.get(`/api/v2/devices?page=0&per_page=10000&activate=true`, credentials);

const getDevices = (credentials) => axios.get(`/api/v2/devices${credentials}`);

const getDeviceBySerialId = (serial_id) => axios.get(`/api/v2/devices/${serial_id}`);

const filterDevices = (credentials) => axios.post('/api/v1/device/all', credentials);

const getCountDevices = (credentials) => axios.post('/api/v1/device/all/count', credentials);

const getDeviceDociment = (credentials) => axios.post('/api/v2/devices/document', credentials);

const createDevice = (credentials) => axios.post('/api/v2/devices', credentials);

const exportDevices = (credentials) =>
  axios.post('/api/v1/device/all/export', credentials, {responseType: 'blob'});

const exportNewDevices = (credentials) =>
  axios.post(`/api/v2/devices/export?${credentials}`, {}, {responseType: 'blob'});

const deleteDevice = (device_serial) =>
  axios.delete(`/api/v2/devices/?device_serial=${device_serial}`);

const deleteRejectedDevice = (requestId, deviceId) =>
  axios.delete(`/api/v1/device/rejected/${requestId}/${deviceId}`);

const getDevicessSuggestionList = (device_serial) =>
  axios.get(`/api/v2/devices/suggestion?page=0&per_page=10&device_serial=${device_serial}`);

export default {
  getAllDevices,
  getDevices,
  filterDevices,
  getCountDevices,
  exportDevices,
  exportNewDevices,
  deleteDevice,
  deleteRejectedDevice,
  getDeviceBySerialId,
  getDeviceDociment,
  createDevice,
  getDevicessSuggestionList,
};
