<template lang="pug">
.inspection-card__table.col-14
  .row
    .col-14
      .table__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column.title.title--small(
          @click="getDocuments"
          class=['text-color--blue-dark', 'mb-2', 'mb-xl-0', 'block-history-title']
        ) МЕДИЦИНСКИЕ ДОКУМЕНТЫ
        span.d-flex.align-items-center.ms-3
          app-svg-icon(name="down-arrow" :class='{rotate: isActiveIcon}')

      .profile-table
    transition(name="translucent")
      .profile-table(v-if='showDocs')
        .profile-table__header.d-none.d-xl-block(v-if="isLoading") Документы загружаются
        .profile-table__header.d-none.d-xl-block(v-if="docs.length")
          li(v-for="doc in docs" :key="doc.id")
            a(:href="doc.file_link") {{ generateDocName(doc) }}
        .profile-table__header.d-none.d-xl-block(v-if='docs.length === 0') Документы отсутствуют
</template>

<script>
import AppTooltip from '@/components/common/Tooltip';
import AppSvgIcon from '@/components/common/SvgIcon';
import patientApi from '@/api/patients';
import AppLoading from '@/components/common/Loading';

export default {
  name: 'DoctorExamCardMedDocs',
  components: {
    AppSvgIcon,
    AppTooltip,
    AppLoading,
  },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActiveIcon: false,
      docs: {},
      showDocs: false,
      isLoading: false,
    };
  },
  methods: {
    async getDocuments() {
      this.isActiveIcon = !this.isActiveIcon;
      this.showDocs = !this.showDocs;
      if (!this.docs.length) {
        this.isLoading = true;
      }
      let res = await patientApi.medDocsUserNew(this.exam.patient_code);
      this.docs = res.data.items;
      this.isLoading = false;
    },
    generateDocName(doc) {
      let typeOpts = {
        health_certificate: 'Cправка о состоянии здоровья',
        injury_certificate: 'Cправка о травме',
        hti_certificate: 'Справка о результатах ХТИ',
        medical_exam_conclusion: 'Заключение предварительного или периодический мед. осмотра',
        medical_exam_certificate: 'Акт медицинского освидетельствования',
        client_request: 'Официальное обращение клиента',
      };
      return `${typeOpts[doc.document_type]} № ${doc.id} от ${this.$moment(
        doc.create_datetime
      ).format('DD.MM.YYYY')}`;
    },
  },
};
</script>

<style lang="scss">
.inspection-card__table {
  a {
    color: #59658d;
  }
  margin-left: auto;
  .table__header {
    height: 50px;
    border-radius: 6px;
    padding: 1rem;
    &__button {
      margin: 0 auto;
      @include mediaToDown($xl) {
        top: $spacer * 2;
      }
    }
  }
  .profile-table__header {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
  }
}
.doctor-exam-detail-modal__docs {
  a {
    display: block;
    text-decoration: underline;
  }
}
.rotate {
  transform: rotate(-180deg);
}
</style>
