<template lang="pug">
.profile-exams__inner
  .row.gx-3.text-size--middle
    .col-3.text-color--black
      span.flex-shrink-0.me-2 №
      span {{ exam.exam_number }} ({{ this.formattedTime }})
    .col-3
      span.flex-shrink-0.me-2 {{ $moment(exam.exam_datetime).format('DD.MM.YYYY') }}
      span {{ $moment(exam.exam_datetime).format('HH:mm') }}
    .col-4
      span.flex-shrink-0.me-2 {{ exam.exam_type_name }} &nbsp;
    .col-4
      span.flex-shrink-0.me-2 {{ exam.patient_full_name }}
  .row.gx-3.mt-4.text-size--middle
    .col-6
      .d-flex
        span.flex-shrink-0.me-2 Рекомендация:&nbsp;
          span.text-color--orange(v-if="medDoctorRecomendations === 'Отстранить'") {{ medDoctorRecomendations }}
          span.text-color--success(v-if="medDoctorRecomendations === 'Допустить'") {{ medDoctorRecomendations }}
        label.form__checkbox.ms-2
          input(
            type="checkbox"
            name="user-type"
            v-model="protector"
          )
          i.form__checkbox-icon.me-3
          span.form__checkbox-caption Отключить
    .col-4
      span.flex-shrink-0.me-2 {{ exam.device_owner_full_name }}
    .col-4
      span.flex-shrink-0.me-2 Пол: {{ exam.gender === 1 ? 'Муж.' : 'Жен.' }} {{ age }} {{ agePostfix }}
  .row.mt-3
    .col-14
      .doctor-exam-detail-modal.innerExamDetailDoctor
        .row.gx-4
          .col-14
            .border-line(:class="{'allow': medDoctorRecomendations === 'Допустить', 'not_allow': medDoctorRecomendations === 'Отстранить'}")
          .col-11
            .row.gx-3
              .col-6
                .innerExamDetailDoctor__ava
                  app-avatar(
                    :localUrl="exam.patient_avatar"
                    :viewPhoto="true"
                    @openGallery="openGallery"
                    :classes="'square extra-large'"
                )
                  p.w-100.text-color--blue-dark.text-center.pointer.underline.mt-2.text-uppercase(v-if="exam.patient_avatar" @click='archive') Архивировать фото
              .col-8
                  template(v-if="exam")
                    transition(name="fade" mode="out-in")
                      doctor-exam-card-video(
                        :src="exam.exam_video"
                        :stopVideo="stopVideo"
                      )
                  .text-color--gray-light(v-else)
                    app-svg-icon(name="no-video")

            doctor-exam-card-inspection-result(
              :exam="exam"
            )
            doctor-exam-card-med-docs-history(
              :exam="exam"
              :id="exam.patient_code"
            )
            doctor-exam-card-med-docs(
              :exam="exam"
            )
          .col-3
            doctor-exam-card-signatures(
              :exam="exam"
            )
            exam-detail-comments(:patientCode="exam.patient_code")

        .row.gx-5.mt-4.pt-2
          .col-7
            .row.gx-5
              .col-7
                button(v-if="exam.type_id == 3 || exam.type_id == 1")(
                  type="button"
                  @click="openModal('reasons'), admissionValue = 'no', typeAdmission = `${exam.type_id}no`, clearCounter = clearCounter + 1"
                  class=['button', 'button--border-blue', 'w-100']
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="wrong")
                    span отстранить

                button(v-if="exam.type_id == 2 || exam.type_id == 4|| exam.type_id == 5")(
                  type="button"
                  @click="openModal('reasons'), admissionValue = 'no', typeAdmission = `${exam.type_id}no`, clearCounter = clearCounter + 1"
                  class=['button', 'button--border-blue', 'w-100']
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="wrong")
                    span не прошел

              .col-7
                button(
                type="button"
                :disabled="prolongationUsed"
                @click="addToTimer"
                class=['button', 'button--shadow', 'w-100']
                )
                  span.button__content.d-flex.align-items-center.justify-content-center продлить

          .col-7
            .row.gx-5
              .col-7
                button(
                  type="button"
                  @click="stopAfterExam"
                  class=['button', 'button--shadow', 'w-100']
                )
                  span.button__content.d-flex.align-items-center.justify-content-center.green пауза

              .col-7
                button(v-if="exam.type_id == 2 || exam.type_id == 4 || exam.type_id == 5 ")(
                  type="button"
                  :disabled=" exam.alcohol_alarm && !protector"
                  @click="openModal('reasons'), admissionValue = 'yes', typeAdmission = `${exam.type_id}yes`, clearCounter = clearCounter + 1"
                  class=['button', 'button--accent', 'w-100']
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="allright")
                    span Прошел

                button(v-if="exam.type_id == 3 || exam.type_id == 1")(
                  type="button"
                  :disabled="!reject_reasons.approve && !protector"
                  @click="onSetAdmission('yes'), admissionValue = 'yes', typeAdmission = `${exam.type_id}yes`"
                  class=['button', 'button--accent', 'w-100']
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="allright")
                    span Допустить

  //Reasons modal
  app-doctor-reasons(
    :is-visible="isModalVisible.reasons"
    @close="closeModal('reasons')"
    :data="exam"
    :reject_reasons_ids="reject_reasons.reject_reason_ids"
    :clearForm="clearForm"
    :type="typeAdmission"
    @refuseOpen='refuseOpen'
    @sendReasons="onSetAdmission('yes')"
    @cleanReasons='cleanReasons'
    :time='clearCounter'
    :protector="protector"
  )
  // Отказать причина
  app-doctor-reasons-refuse(
    :is-visible="isModalVisible.refuse"
    @closeReasons="closeModal('reasons')"
    @close="closeModal('refuse')"
    @setRefuseText='setRefuseText'
    @sendReasons="onSetAdmission('no')"
    :type="typeAdmission"
    :data="exam"
    :reasons="reasons"
    :time='clearCounter'
  )

    // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="loading" :withoutCloseBtn="true")
      template(v-slot:body)
        .text-center.pt-4.d-flex.flex-column.justify-content-center.align-items-center
          div(class="spinner-border mb-4" role="status")
          app-title(:type="'large'") Документ находится на подписании
          p.text-size--small.text-color--blue.mt-3 Не обновляйте страницу и не закрывайте данное окно

</template>

<script>
import {mapState} from 'vuex';
import AppAvatar from '@/components/profile/common/Avatar';
import {videoPlayer} from 'vue-video-player';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppSignature from '@/components/profile/common/Signature';
import ExamDetailComments from '@/components/profile/admin/patients/ExamDetailComments';
import DoctorExamCardVideo from '@/components/profile/doctor/exams/DoctorExamCardVideo';

import DoctorExamCardInspectionResult from '@/components/profile/doctor/exams/DoctorExamCardInspectionResult';
import DoctorExamCardMedDocsHistory from '@/components/profile/doctor/exams/DoctorExamCardMedDocsHistory';
import DoctorExamCardMedDocs from '@/components/profile/doctor/exams/DoctorExamCardMedDocs';

import AppDoctorReasons from '@/components/profile/doctor/exams/AppDoctorReasons';
import AppDoctorReasonsRefuse from '@/components/profile/doctor/exams/AppDoctorReasonsRefuse';

import DoctorExamDetailModalTable from './DoctorExamDetailModalTable';

import AppModal from '@/components/common/Modal';
import {modalsMixin} from '@/mixins/modals';

import examsApi from '@/api/exams';

import {mapGetters} from 'vuex';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import {getUserCertificates, createDetachedSignature, createHash} from 'crypto-pro';
import filesApi from '@/api/files';
import patientApi from '@/api/patients';
// import doctorsApi from '@/api/doctors';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';
import {toLocalDate, toLocalTime, toLocalTimeExam} from '@/helpers/utils';

import AppGallery from '@/components/common/Gallery';
import {galleryMixin} from '@/mixins/gallery';

import DoctorExamCardSignatures from '@/components/profile/doctor/exams/DoctorExamCardSignatures';
import AppTitle from '@/components/common/Title';

export default {
  components: {
    AppAvatar,
    videoPlayer,
    AppSvgIcon,
    AppSignature,
    ExamDetailComments,
    AppDoctorReasons,
    AppDoctorReasonsRefuse,
    DoctorExamDetailModalTable,
    DoctorExamCardVideo,
    DoctorExamCardInspectionResult,
    DoctorExamCardMedDocsHistory,
    DoctorExamCardMedDocs,
    AppGallery,
    DoctorExamCardSignatures,
    AppModal,
    AppTitle,
  },
  mixins: [modalsMixin, galleryMixin],
  props: {
    uuid: {
      required: true,
      type: String,
    },
    showed: {
      required: true,
      type: Boolean,
      default: false,
    },
    examProcessed: {
      required: true,
      type: Function,
    },
    examReturned: {
      required: true,
      type: Function,
    },
    sendExamAndStop: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      uuidData: '',
      loading: false,
      isModalVisible: {
        selectCertificate: false,
        error: false,
        refuse: false,
        reasons: false,
      },
      patientSign: '',
      stopVideo: false,
      clearForm: false,
      prolongationUsed: false,
      timeLeft: 50,
      stopWork: false,
      timerOnStop: false,
      timer: null,
      typeAdmission: '',
      exam: {
        exam_video: 'test',
      },
      personal_borders: {},
      avgExams: {},
      patientDocs: [],
      reasons: [],
      certificates: {
        all: [],
        selected: null,
      },
      result_aprove: true,
      reject_reasons: {
        reject_reason_ids: [],
        approve: true,
      },
      protector: false,
      error: {
        title: 'Произошла ошибка',
        description: 'Пожалуйста, попробуйте ещё раз через некоторое время',
      },
      video: {
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{type: 'video/mp4', src: ''}],
        poster: '',
      },
      isProcess: true,
      clearCounter: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      settings: (state) => state.settings.data,
    }),
    formattedTime() {
      let minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;
      return `${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    age() {
      const birthDate = new Date(this.exam.date_birth);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    agePostfix() {
      if (!this.age) return '';
      const lastDigit = this.age % 10;
      if (lastDigit === 1 && this.age !== 11) {
        return 'год';
      } else if (lastDigit >= 2 && lastDigit <= 4 && (this.age < 10 || this.age > 20)) {
        return 'года';
      } else {
        return 'лет';
      }
    },
    medDoctorRecomendations() {
      if (
        (this.exam.type_id == 2 && this.exam.alcohol_alarm) ||
        (this.exam.type_id == 4 && this.exam.alcohol_alarm) ||
        (this.exam.type_id == 5 && this.exam.alcohol_alarm)
      ) {
        return 'Отстранить';
      }
      if (
        (this.exam.type_id == 2 && !this.exam.alcohol_alarm) ||
        (this.exam.type_id == 4 && !this.exam.alcohol_alarm) ||
        (this.exam.type_id == 5 && !this.exam.alcohol_alarm)
      ) {
        return 'Допустить';
      }
      if (
        (this.exam.type_id == 1 && !this.reject_reasons.approve) ||
        (this.exam.type_id == 3 && !this.reject_reasons.approve)
      ) {
        return 'Отстранить';
      }
      if (
        (this.exam.type_id == 1 && this.reject_reasons.approve) ||
        (this.exam.type_id == 3 && this.reject_reasons.approve)
      ) {
        return 'Допустить';
      } else {
        return '';
      }
    },
  },
  watch: {
    showed: async function (newVal) {
      if (newVal !== true) {
        this.clearForm = true;
        this.stopVideo = true;
        clearInterval(this.timer);
        return;
      } else {
        this.timeLeft = 80;
        this.reasons = [];
        this.reasonsText = '';
        this.video = {
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{type: 'video/mp4', src: ''}],
          poster: '',
        };
        this.isModalVisible.reasons = false;
        this.isModalVisible.refuse = false;
        this.stopWork = false;
        this.stopVideo = false;
        this.clearForm = false;
        this.timer = setInterval(() => {
          if (this.timeLeft <= 0) {
            //убрала запрос, пока он не активен на бэке

            // this.uuidData = JSON.parse(this.uuid);
            // doctorsApi.doctorStatsEvent({
            //   exam_id: this.uuidData['id'],
            //   event_type: 'time_return_to_queue',
            // });
            this.prolongationUsed = false;
            clearInterval(this.timer);
            this.examReturned();
            this.timeLeft = 10;
            return;
          }
          if (!this.timerOnStop) {
            this.timeLeft = this.timeLeft - 1;
          }
        }, 1000);
        await this.getExamData();
      }
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  async created() {
    this.getExamData();
  },
  methods: {
    //get exam data
    async getExamData() {
      try {
        if (this.uuid) {
          this.uuidData = JSON.parse(this.uuid);
          let data = await examsApi.getExamDetail(this.uuidData['id']);

          // убрала запрос, пока он не активен на бэке
          // doctorsApi.doctorStatsEvent({
          //   exam_id: this.uuidData['id'],
          //   event_type: 'time_to_work',
          // });

          this.exam = data.data;
          this.exam.exam_datetime += 'Z';
          this.video['sources'][0]['src'] = this.exam.exam_video;

          let result = await patientApi.getPatientBorders(this.exam.patient_code);
          this.personal_borders = result.data;

          let patientDocs = await patientApi.medDocsUser({
            filters: {
              patients: {
                negative: false,
                payload: [this.exam.patient_code],
              },
            },
            pagination: {
              offset: 0,
              limit: 200,
            },
          });
          this.patientDocs = patientDocs.data.result;

          let reject_reasons = await examsApi.getExamsReasons(this.uuidData['id']);
          this.reject_reasons = {
            reject_reason_ids: reject_reasons.data.reject_reason_ids || [],
            approve: !reject_reasons.data.reject_reason_ids.length,
          };

          let avgExams = await examsApi.avgExams(this.exam.patient_code);
          this.avgExams = avgExams.data;

          let res = await patientApi.getPatientDetail(this.exam.patient_code);
          this.patientSign = res.data.signature;
        } else {
          return;
        }
      } catch (error) {
        console.error('Ошибка в getExamData:', error);
      }
    },

    //set admission to work
    onSetAdmission() {
      this.loading = true;
      this.timerOnStop = true;
      this.getCertificates();
    },
    getCertificates() {
      getUserCertificates(true)
        .then(async (certificates) => {
          this.certificates.all = certificates;
          for (let certificate of certificates) {
            let owner = await certificate.getOwnerInfo();
            let isValidLastName = false;
            let isValidFirstName = false;
            let isValidMiddleName = true;
            owner.forEach((el) => {
              if (el.title === 'Фамилия')
                isValidLastName = el.description === this.currentUser.last_name;
              if (el.title === 'Имя Отчество') {
                isValidFirstName = el.description.split(' ')[0] === this.currentUser.first_name;
                if (this.currentUser.middle_name)
                  isValidMiddleName = el.description.split(' ')[1] === this.currentUser.middle_name;
              }
            });
            let result = Boolean(isValidLastName && isValidFirstName && isValidMiddleName);
            if (result) {
              this.isProcess = true;
              this.certificates.selected = certificate;
              this.getSignature();
              return null;
            }
          }
          this.error.title = 'Ошибка при проверке сертификата';
          this.error.description =
            'Ваше ФИО не совпадает с ФИО владельца сертификата.<br>Попробуйте выбрать другой серификат.';
          this.openModal('error');
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },
    getOwnerInfoCertificate(certificate, callback = false) {
      certificate
        .getOwnerInfo()
        .then((owner) => {
          certificate.owner = owner;
          certificate
            .getIssuerInfo()
            .then((issuer) => {
              certificate.issuer = issuer;
              if (callback) this.openModal('selectCertificate');
            })
            .catch((error) => {
              this.error.title = error;
              this.openModal('error');
            });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },
    onSelectCertificate(certificate) {
      this.certificates.selected = certificate;
    },
    getSignature() {
      const document = this.createDocument();
      const formData = new FormData();
      formData.append('exam_id', this.exam.exam_id);
      formData.append(
        'admission_datetime',
        new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString()
      );
      formData.append('admission_to_work', this.admissionValue);
      formData.append('data', new Blob([document]), 'commission.json');
      formData.append('number_kep', this.certificates.selected.thumbprint);
      formData.append('owner_kep', this.certificates.selected.name);
      formData.append(
        'validity_kep',
        `с ${this.toLocalDate(this.certificates.selected.validFrom)} по ${this.toLocalDate(
          this.certificates.selected.validTo
        )}`
      );
      formData.append('exam_reasons', this.reasonsToIds(this.reasons));
      formData.append('exam_comment', this.reasonText);
      this.reasons = [];
      this.reasonText = '';

      createHash(document)
        .then((messageHash) => {
          createDetachedSignature(this.certificates.selected.thumbprint, messageHash)
            .then((signature) => {
              formData.append('sert_thumbprint', this.certificates.selected.thumbprint);
              formData.append('sign_file', new Blob([signature]), 'commission.sig');
              this.setAdmission(formData);
            })
            .catch((error) => {
              this.error.title = error;
              this.openModal('error');
            });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },

    setAdmission(formData) {
      this.$store
        .dispatch(doctorsActionTypes.setAdmission, formData)
        .then(() => {
          this.close();
          this.$toast.success('Заключение сформировано');
          this.protector = false;
          this.prolongationUsed = false;
          this.$emit('success');
          if (this.stopWork) {
            this.sendExamAndStop();
          } else {
            this.examProcessed();
          }
        })
        .catch(() => {
          this.close();
          this.error.title = 'Ошибка при формировании заключения';
          this.openModal('error');
        })
        .finally(() => {
          this.loading = false;
          this.timerOnStop = false;
        });
    },

    //create document for loading
    createDocument() {
      const content = {
        date: `${this.toLocalDate(this.exam.exam_datetime)} ${this.toLocalTime(
          this.exam_datetime
        )}`,
        patient: {
          name: this.exam.patient_full_name,
          gender: this.exam.gender === 2 ? 'Женский' : 'Мужской',
          birthdate: this.toLocalDate(this.exam.date_birth),
        },
        bpHigh: this.exam.bp_high,
        bpLow: this.exam.bp_low,
        heartRate: this.exam.heart_rate,
        temperature: this.exam.body_temp,
        alcohol: this.exam.alcohol,
        healthComplaint: this.exam.health_complaint ? this.exam.health_complaint : 'Нет',
        doctorDecision: this.admissionValue === 'yes' ? 'Допущен' : 'Не допущен',
      };
      return JSON.stringify(content);
    },

    //archive
    async archive() {
      await patientApi.archivePatientAvatar(this.exam.patient_code, {
        patient_avatar: 'archived',
      });
      this.exam.patient_avatar = null;
      this.reject_reasons.approve = false;
      this.admissionValue = 'no';
      this.typeAdmission = `${this.exam.type_id}no`;
      this.clearCounter = this.clearCounter + 1;
      //Передаем причину отстранения по архивации
      this.reasons = [
        {
          checked: true,
          id: 30,
        },
      ];
      this.onSetAdmission('no');
    },
    //comments
    async createComment() {
      await patientApi.createPatientComment({
        patient_code: this.data.patient_code,
        comment: this.currentComment,
      });
      this.currentComment = '';
      let comments = await patientApi.getPatientComment(this.data.patient_code);
      comments = await comments.json();
      this.comments = comments.items;
    },

    //exam
    refuseOpen(items) {
      this.reasons = items;
      this.openModal('refuse');
    },
    setRefuseText(reasonText) {
      this.reasonText = reasonText;
    },
    addToTimer() {
      this.timeLeft += 20;
      this.prolongationUsed = true;
    },
    stopAfterExam() {
      this.stopWork = true;
    },

    //close clear
    close() {
      this.reset();
      this.$emit('close');
    },
    reset() {
      this.isProcess = false;
      this.certificates.selected = null;
      this.admissionValue = null;
      this.prolongationUsed = false;
    },
    cleanReasons() {
      this.reasons = [];
    },

    //reasons
    reasonsToIds(reasons) {
      let result = [];
      let injuries = {
        'Травма: Волосистой области, слева': 32,
        'Травма: Волосистой области, справа': 33,
        'Травма: Волосистой области': 34,
        'Травма: Околоушно-жевательной области, слева': 35,
        'Травма: Околоушно-жевательной области, справа': 36,
        'Травма: Околоушно-жевательной области, слева, справа': 37,
        'Травма: Лобной области, слева': 38,
        'Травма: Лобной области, справа': 39,
        'Травма: Лобной области': 40,
        'Травма: Уха, слева': 41,
        'Травма: Уха, справа': 42,
        'Травма: Уха, слева, справа': 43,
        'Травма: Височной области, слева': 44,
        'Травма: Височной области, справа': 45,
        'Травма: Височной области, слева, справа': 46,
        'Травма: Мягких тканей лица, слева': 47,
        'Травма: Мягких тканей лица, справа': 48,
        'Травма: Мягких тканей лица': 49,
        'Травма: Надбровной области, слева': 50,
        'Травма: Надбровной области, справа': 51,
        'Травма: Надбровной области, слева, справа': 52,
        'Травма: Верхней губы, слева': 53,
        'Травма: Верхней губы, справа': 54,
        'Травма: Верхней губы': 55,
        'Травма: Параорбитальная гематома, слева': 56,
        'Травма: Параорбитальная гематома, справа': 57,
        'Травма: Параорбитальная гематома, слева, справа': 58,
        'Травма: Нижней губы, слева': 59,
        'Травма: Нижней губы, справа': 60,
        'Травма: Нижней губы': 61,
        'Травма: Века, слева': 62,
        'Травма: Века, справа': 63,
        'Травма: Века, слева, справа': 64,
        'Травма: Подбородочной области, слева': 65,
        'Травма: Подбородочной области, справа': 66,
        'Травма: Подбородочной области': 67,
        'Травма: Воспаление века неуточненное, слева': 68,
        'Травма: Воспаление века неуточненное, справа': 69,
        'Травма: Воспаление века неуточненное, слева, справа': 70,
        'Травма: Множественные травмы головы, слева': 71,
        'Травма: Множественные травмы головы, справа': 72,
        'Травма: Множественные травмы головы': 73,
        'Травма: Подглазничной области, слева': 74,
        'Травма: Подглазничной области, справа': 75,
        'Травма: Подглазничной области, слева, справа': 76,
        'Травма: Шеи неуточненная, слева': 77,
        'Травма: Шеи неуточненная, справа': 78,
        'Травма: Шеи неуточненная': 79,
        'Травма: Скуловой области, слева': 80,
        'Травма: Скуловой области, справа': 81,
        'Травма: Скуловой области, слева, справа': 82,
        'Травма: Грудной клетки неуточненная': 85,
        'Травма: Спинки носа': 88,
        'Травма: Верхней конечности, слева': 89,
        'Травма: Верхней конечности, справа': 90,
        'Травма: Верхней конечности, слева, справа': 91,
        'Травма: Носа, слева': 92,
        'Травма: Носа, справа': 93,
        'Травма: Носа': 94,
        'Травма: Нижней конечности, слева': 95,
        'Травма: Нижней конечности, справа': 96,
        'Травма: Нижней конечности, слева, справа': 97,
      };
      for (let reason of reasons) {
        if ('checked' in reason && reason.text !== 'Травма') {
          result.push(reason.id);
        } else {
          if (reason.both) {
            result.push(injuries[`Травма: ${reason.text}`]);
          } else if (reason.both == null && reason.left && reason.right) {
            result.push(injuries[`Травма: ${reason.text}, слева, справа`]);
          } else {
            if (reason.left) {
              result.push(injuries[`Травма: ${reason.text}, слева`]);
            }
            if (reason.right) {
              result.push(injuries[`Травма: ${reason.text}, справа`]);
            }
          }
        }
      }
      return result.join(',');
    },

    //certificate video map
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    initializedMap(instance) {
      this.map.isLoading = false;
      this.map.instance = instance;
      // eslint-disable-next-line no-undef
      ymaps.ready(() => (this.map.ymaps = ymaps));
    },
    getVideo(hash) {
      if (hash) {
        this.video.isLoading = true;
        filesApi.getVideo(hash).then((url) => {
          this.video.sources[0].src = url;
          this.videoSrc = url;
          this.video.isLoading = false;
        });
      }
    },

    //loader
    handleBeforeUnload(event) {
      if (this.loading) {
        const message =
          'Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны.';
        event.returnValue = message;
        return message;
      }
    },

    //common
    pad(number) {
      return number < 10 ? `0${number}` : number;
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.innerExamDetailDoctor {
  background: #fff;
  padding: 20px 15px 20px;
}

.innerExamDetailDoctor__ava,
.innerExamDetailDoctor__video {
  margin-bottom: 0px;
  figure {
    width: 100% !important;
    height: 300px !important;
  }
}

.innerExamDetailDoctor__ava {
  p {
    margin-bottom: 0;
  }
}

.innerExamDetailDoctor__signs {
  text-align: center;
}

.innerExamDetailDoctor__signs-item {
  height: 125px;
  padding-top: 11px;
  margin-bottom: 7px;
  background: #ffffff;
  box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
}

.innerExamDetailDoctor__signs-item-lbl {
  text-align: center;
}

.innerExamDetailDoctor__video {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: get($gradients, blue-light);
  border-radius: 0;

  .icon__no-video {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.innerExamDetailDoctor__table {
  color: #59658d;

  thead {
    font-size: 12px;

    th {
      font-weight: 400;
      padding-bottom: 8px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #59658d;

      &:last-child {
        border: none;
      }
    }

    td {
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    td:first-child {
      font-size: 12px;
    }
  }
}

.border-line {
  width: 100%;
  height: 6px;
  background: get($gradients, orange);
}
.allow {
  background: get($gradients, turq);
}
.disabledProtector {
  background: get($gradients, grey);
}
.turq {
  color: #12b048;
}
.not_allow {
  background: get($gradients, orange);
}
.not-allow-button {
  background: get($gradients, orange);
  color: white;
}
.spinner-border {
  color: get($colors, turq);
}
</style>
